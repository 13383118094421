import React, { useState } from 'react';
import { coupons } from '../../../assets/Coupons';  // Import coupons data
import { checkDiscountCodeValidity } from './DiscountHelpers';

/**
 * CouponModal
 * Displays a modal where users can select a coupon to apply to their payment.
 * The coupons are shown in a "ticket-like" design with clear information on the discount offered.
 * 
 * @param {function} handleCouponSelection  - Function to apply the selected coupon and close the modal.
 * @param {function} setShowCouponModal     - Function to close the coupon modal.
 * @param {Number} duration                 - The number of months the user is buying the membership for.
 * @returns {JSX.Element} The coupon selection modal.
 */
const CouponModal = ({ handleCouponSelection, setShowCouponModal, duration }) => {
    const [discountCode, setDiscountCode] = useState(''); // State to store the coupon code

    // Filter coupons based on the minDuration
    const filteredCoupons = coupons.filter(coupon => {
        return (
            coupon.minDuration <= duration && 
            (coupon.maxDuration === undefined || duration <= coupon.maxDuration)
        );
    });

    /**
     * handleDiscountCodeSubmit
     * Checks the validity of the discount code entered by the user.
     * If valid, apply the discount; otherwise, show an alert.
     */
    const handleDiscountCodeSubmit = async () => {
        const discountCoupon = await checkDiscountCodeValidity(discountCode); // Check coupon validity
        if (discountCoupon.type) {
            handleCouponSelection(discountCoupon); // Apply the valid coupon
        } else {
            alert('This code doesn\'t exist or is expired.');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
            <div className="relative bg-secondary text-tertiary p-6 sm:p-8 rounded-3xl shadow-lg w-11/12 sm:w-full max-w-xl sm:max-w-3xl mx-auto">
                {/* Close button (X) */}
                <button 
                    onClick={() => setShowCouponModal(false)} 
                    className="absolute top-4 right-4 text-tertiary text-4xl hover:text-primary transition"
                >
                    &times;
                </button>

                {/* Coupons List (displayed as "tickets") */}
                <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-4 sm:mb-6">Available Coupons</h2>
                <div className="space-y-4 sm:space-y-6">
                    {filteredCoupons.length > 0 ? (
                        filteredCoupons.map((coupon) => (
                            <div 
                                key={coupon.code} 
                                className="border-2 border-secondary bg-tertiary text-black p-4 sm:p-6 rounded-3xl flex flex-col sm:flex-row justify-between items-center cursor-pointer hover:bg-primary hover:text-tertiary transition"
                                onClick={() => handleCouponSelection(coupon)}
                            >
                                <div className="text-center sm:text-left">
                                    <p className="font-bold text-lg sm:text-xl">{coupon.description}</p>
                                    <p className="text-sm text-logoGray">Code: {coupon.code}</p>
                                </div>
                                <p className="text-xl sm:text-2xl text-secondary font-bold mt-4 sm:mt-0">
                                    {coupon.type === 'percentage' ? `${coupon.value}%` : `₹${coupon.value}`} off
                                </p>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-logoGray">No coupons available for this duration.</p>
                    )}
                </div>

                {/* Coupon Code Input */}
                <div className="mt-6 flex items-center justify-left space-x-4">
                    <label htmlFor="couponCode" className="text-lg sm:text-xl">Have a coupon code?</label>
                    <input 
                        type="text" 
                        id="couponCode" 
                        className="border border-tertiary rounded-3xl px-4 py-2 text-lg sm:text-xl text-black"
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                    />
                    <button 
                        onClick={handleDiscountCodeSubmit}
                        className="bg-primary text-tertiary rounded-3xl px-4 py-2 text-lg sm:text-xl hover:bg-tertiary hover:text-primary transition"
                    >
                        Check
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CouponModal;
