import React, { useEffect, useState } from 'react';
import { DisplayPrice, getSellingPrice, extractPrices } from '../MarketplaceHelpers/gymDataHelpers/PriceHelpers';

/**
 * GymMembershipOptions
 * Displays individual membership options for the selected category (Single, Couple, Family).
 * Allows users to choose a plan and open the payment summary modal.
 * 
 * @param {string} label - The label for the membership duration (e.g., "1 month").
 * @param {string} membershipType - The type of membership (e.g., "monthlyPrice").
 * @param {object} gymData - The gym data containing price information.
 * @param {string} selectedCategory - The selected membership category (Single, Couple, Family).
 * @param {function} toggleSummaryModal - Function to open the summary modal with selected details.
 * @returns {JSX.Element} The gym membership options component.
 */
const GymMembershipOptions = ({ label, months, membershipType, gymData, selectedCategory, toggleSummaryModal }) => {
    const { country } = gymData;

    // State to store prices for single, couple, and family memberships
    const [prices, setPrices] = useState({
        single: {},
        couple: null,
        family: null,
    });

    // State to store selling prices for single, couple, and family memberships
    const [sellingPrices, setSellingPrices] = useState({
        single: null,
        couple: null,
        family: null,
    });

    // Loading state to handle async data fetching
    const [loading, setLoading] = useState(true);

    // Fetch and calculate prices and selling prices
    useEffect(() => {
        const setPricingData = async () => {
            setLoading(true); // Set loading to true before fetching
            const extractedPrices = extractPrices(gymData, membershipType);

            // Calculate selling prices using the extracted exclusive prices
            const sellingPriceSingle = extractedPrices.single ? getSellingPrice(extractedPrices.single.exclusivePrice) : null;
            const sellingPriceCouple = extractedPrices.couple ? getSellingPrice(extractedPrices.couple.exclusivePrice) : null;
            const sellingPriceFamily = extractedPrices.family ? getSellingPrice(extractedPrices.family.exclusivePrice) : null;

            // Set prices and selling prices in state
            setPrices(extractedPrices);
            setSellingPrices({
                single: sellingPriceSingle,
                couple: sellingPriceCouple,
                family: sellingPriceFamily,
            });
            setLoading(false); // Set loading to false once data is fetched
        };

        setPricingData();
    }, [membershipType, gymData]);

    // Get the correct price category based on selectedCategory
    const selectedPrices = prices[selectedCategory] || null;
    const currentSellingPrices = sellingPrices[selectedCategory] || {};

    if (loading) {
        return (
            <div className="flex justify-center py-4">
                <p>Loading Prices...</p>
            </div>
        );
    }

    return (
        <div>
            <div className="flex justify-between lg:px-4 py-2">
                <p className="text-primary ml-10 text-center text-sm md:text-lg font-semibold lg:py-2">{label}</p>

                {selectedPrices && (currentSellingPrices > 0) ? (
                    <div className="flex justify-center">
                        <button
                            onClick={() => toggleSummaryModal(months, currentSellingPrices, selectedPrices.mrp)}
                            className={`mr-10 text-primary text-sm md:text-lg font-semibold lg:bg-secondary rounded-full px-0 lg:w-52 text-center ${selectedPrices.mrp ? 'opacity-100' : 'opacity-60'}`}
                            disabled={!selectedPrices.mrp}
                        >
                            <DisplayPrice
                                mrp={selectedPrices.mrp}
                                sellingPrice={currentSellingPrices}  // Access the correct selling price
                                country={country}
                            />
                        </button>
                    </div>
                ) : (
                    <div className="flex justify-center">
                        <button className="mr-10 text-tertiary text-sm md:text-base lg:text-lg font-semibold bg-primary rounded-full px-6 py-1 lg:py-0 text-center cursor-default">
                            No Prices Available
                        </button>
                    </div>
                )}
            </div>

            <div className="flex mx-[12px] lg:mx-4 border-t-[1px] border-secondary lg:my-4"></div>
        </div>
    );
};

export default GymMembershipOptions;
