import { handlePaymentFailure, paymentSuccessHandler } from "./MembershipHelper";
import { getAmount, getCurrency } from "./PaymentHelpers";
import { themeColors } from "../theme";
import icon from "../assets/Icon.jpeg"

/**
 * Generates a razorpay link
 * @returns Razorpay window script
 */
const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
};

/**
 * Handles Main Razorpay payment method
 * @param {Object} myData                       - Contains the data required for process the payment
 * @param {Function} setIsPaymentProcessing     - Function to diplay loading
 * @param {Function} setSuccessModal            - Displays a success modal
 * @param {Function} setBillInfo                - Sets the Bill Generation Info (to be used in Success Modal)
 * @returns 
 */
export const handlePayment = async (myData, setIsPaymentProcessing, setSuccessModal, setBillInfo) => {
    const res = await loadRazorpayScript();

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return false; // Return false if Razorpay fails to load
    }

    return new Promise((resolve) => {
        const options = {
            key: 'rzp_live_PjuEiFCgxSVySz',        // Live Mode
            // key: 'rzp_test_xWMw9b7Eg3ntwK',     // Test Mode
            amount: myData.amount, // Amount in currency subunits (50000 refers to ₹500.00)
            currency: getCurrency(),
            name: 'Train Rex',
            description: 'Gym Membership',
            image: icon,
            handler: async function (response) {
                // If payment is successful, add data to firestore
                const res = await paymentSuccessHandler(myData, response, setIsPaymentProcessing, setBillInfo);
                if (res) {
                    resolve(true); // Resolve the promise with true on success
                    setSuccessModal(true);
                } else {
                    resolve(false); // Resolve with false on failure
                }
            },
            prefill: {
                name    : myData.user.displayName,
                email   : myData.user.email,
                contact : myData.user.phoneNumber,
            },
            notes: {
                userID: myData.user.uid,
            },
            theme: {
                color: themeColors.fg,
            },
            modal: {
                ondismiss: function () {
                    handlePaymentFailure(setIsPaymentProcessing);
                    resolve(false); // Resolve with false if the modal is dismissed
                },
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    });
};

