import React, { useState, useEffect } from 'react';
import logoSVG from '../../assets/Website_content/TR_Website_content-02.svg';
import AppButton from "../../components/AppButton";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase';
import FormComponent from '../../components/FormComponent';
import { MonthlyExpertPlan_Modal } from '../MonthlyExpertPlan_Modal';
import { useNavigate } from 'react-router-dom';

const Navbar_App_helper = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [showRexpertPlan, setShowRexpertPlan] = useState(false);
    const [isMarketPlace, setIsMarketPlace] = useState(false);
    const [activeSection, setActiveSection] = useState("");
    const navigate = useNavigate();

    const showForm = () => setIsFormVisible(true);
    const hideForm = () => setIsFormVisible(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('showForm') === 'true') {
            setIsFormVisible(true);
        }
    }, []);

    const handleFormSubmit = async(name, email, feedback) => {
        if (name === '' || email === '' || feedback['message'] === '') {
            alert('Error', 'Please fill in all fields.');
            return;
        }

        try {
            const response = await fetch('https://test-dot-gym-bro-101ca.ew.r.appspot.com/submit_feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({name, email, feedback}),
            });

            if (response.ok) {
                alert('Thank you for your feedback!');
            } else {
                const errorMessage = await response.text();
                // console.error(`Failed to send data to the server. Status: ${response.status}, Error: ${errorMessage}`);
            }
        } catch (error) {
            // console.error('Error uploading feedback:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024 && menuOpen) {
                setMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [menuOpen]);

    useEffect(() => {
        const handleScroll = () => {
            const aboutSection = document.getElementById("about");
            const featuresSection = document.getElementById("features");
            const contactSection = document.getElementById("contact");
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const bodyHeight = document.body.scrollHeight;

            if (aboutSection && featuresSection && contactSection) {
                if (
                    scrollPosition >= aboutSection.offsetTop &&
                    scrollPosition < featuresSection.offsetTop
                ) {
                    setActiveSection("about");
                } else if (
                    scrollPosition >= featuresSection.offsetTop &&
                    scrollPosition < contactSection.offsetTop - 1000
                ) {
                    setActiveSection("features");
                } else if (scrollPosition + windowHeight > bodyHeight - 100) {
                    setActiveSection("contact");
                } else {
                    setActiveSection("");
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const logMenuItemClick = (itemName) => {
        logEvent(analytics, `menuClick, ${itemName}`);
    };

    function isActive(link) {
        if (link.includes('#')) {
            return activeSection === link.replace('#', '');
        } else {
            return window.location.href.includes(link.replace('#', ''));
        }
    }

    function isTCorPPPage() {
        return window.location.href.includes('/AppDescription/Terms&Conditions') || window.location.href.includes('/AppDescription/PrivacyPolicy') || window.location.href.includes('/AppDescription/Careers');
    }

    const handleRexpert = () => {
        setShowRexpertPlan(true);
    };

    return (
        <div className="bg-tertiary top-0 left-0 right-0 z-50">
            <div className="bg-primary py-4 h-2 flex justify-center items-center z-50">
                <span className="text-lg font-semibold text-tertiary">Available on both iOS and Android</span>
            </div>

            <div className="bg-tertiary py-4 px-16 flex justify-between items-center lg:gap-x-4">
                <div className="block lg:hidden" style={{ marginLeft: -40 }}>
                    <button onClick={toggleMenu} className="text-primary focus:outline-none">
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                            />
                        </svg>
                    </button>
                </div>

                {/* added navigate to marketplace */}
                <div onClick={() => navigate('/')} className="w-2/3 md:w-1/3 flex items-center mb-2 lg:mb-0 cursor-pointer">
                    <img src={logoSVG} alt="Logo" className="h-8" />
                </div>

                <nav className={`w-1/3 lg:flex py-2 px-0 lg:w-auto ${menuOpen ? 'w-auto flex flex-col absolute top-24 left-0 right-0 bottom-auto bg-tertiary z-50 rounded-b-2xl border-x-4 border-b-4 border-secondary' : 'hidden'}`}>
                    {isTCorPPPage() ? (
                        <ul className="md:flex lg:flex-row flex-col text-lg lg:gap-2 gap-2">
                            <li>
                                <a href="/AppDescription" className="block py-2 px-1 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold text-secondary" onClick={() => {
                                    logMenuItemClick('Home');
                                    props.setIsMarketPlace(true);
                                }}>Home</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="/AppDescription/Careers" className={`block py-2 px-1 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold text-secondary`} onClick={() => { logMenuItemClick('Careers'); setMenuOpen(false); }}>Careers</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="/AppDescription/Terms&Conditions" className={`block py-2 px-1 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('TCPage') ? 'text-primary' : 'text-secondary'}`} onClick={() => { logMenuItemClick('T&C'); setMenuOpen(false); }}>T&C</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="/AppDescription/PrivacyPolicy" className={`block py-2 px-1 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('PPPage') ? 'text-primary' : 'text-secondary'}`} onClick={() => { logMenuItemClick('Privacy'); setMenuOpen(false); }}>Privacy Policy</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <button onClick={() => { handleRexpert(); setMenuOpen(false); }} className={`block py-2 px-1 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('Rexpert') ? 'text-primary' : 'text-secondary'}`}>REXpert Plan</button>
                            </li>
                        </ul>
                    ) : (
                        <ul className="md:flex lg:flex-row flex-col text-lg gap-0 md:justify-evenly md:min-w-[55vw]">
                            <li>
                                <a href="#about" className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('about') ? 'text-primary' : 'text-secondary'}`} onClick={() => { setMenuOpen(false); }}>About</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="#features" className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('features') ? 'text-primary' : 'text-secondary'}`} onClick={() => { setMenuOpen(false); }}>Features</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2 text-lg">|</li>
                            <li>
                                <button onClick={() => { showForm(); setMenuOpen(false); }} className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold text-lg ${isActive('ContactUs') ? 'text-primary' : 'text-secondary'}`}>Contact Us</button>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="/AppDescription/Careers" className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold text-secondary`} onClick={() => { logMenuItemClick('Careers'); setMenuOpen(false); }}>Careers</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="/AppDescription/Terms&Conditions" className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('TCPage') ? 'text-primary' : 'text-secondary'}`} onClick={() => { setMenuOpen(false); }}>T&C</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <a href="/AppDescription/PrivacyPolicy" className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('PPPage') ? 'text-primary' : 'text-secondary'}`} onClick={() => { setMenuOpen(false); }}>Privacy Policy</a>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <button onClick={() => { handleRexpert(); setMenuOpen(false); }} className={`block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold ${isActive('Rexpert') ? 'text-primary' : 'text-secondary'}`}>REXpert Plan</button>
                            </li>
                            <li className="text-secondary cursor-default hidden lg:flex pt-2">|</li>
                            <li>
                                <button
                                    className="block py-2 px-4 lg:px-0 rounded-md text-left lg:text-center hover:font-bold hover:scale-125 transition-all duration-300 font-semibold text-secondary"
                                    onClick={() => {
                                        logMenuItemClick('MarketPlace');
                                        navigate('/');
                                    }}>
                                    MarketPlace
                                </button>
                            </li>
                        </ul>
                    )}
                </nav>

                <AppButton
                    style={{ marginRight: -30, marginBottom: 10 }}
                    isDropdownOpen={true}
                />
            </div>
            {isFormVisible && <FormComponent onClose={hideForm} onSubmit={handleFormSubmit} />}
            {showRexpertPlan &&
                <div className="fixed inset-0 bg-tertiary bg-opacity-75 flex items-center justify-center z-50">
                    <MonthlyExpertPlan_Modal className="h-fit z-60" setShowRexpertPlan={setShowRexpertPlan}>
                        <button className="absolute bg-secondary text-4xl">Hi</button>
                    </MonthlyExpertPlan_Modal>
                </div>
            }
        </div>
    );
};

export default Navbar_App_helper;
