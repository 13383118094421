import { removeSpacesAndConvertLowercase } from "../../basicHelper";
import { equipmentCategoryList, importantFacilities, importantClasses } from "./GymMachineryLists";

/**
 * Gets the equipment, classes, and facility lists of the gym, and returns them alphabetically sorted.
 * @param {Object} gymData - The gym data containing equipmentList, facilities, and classes
 * @return {Object} - An object with alphabetically sorted lists of available equipment, facilities, and classes that are set to true
 */
export const getGymMachineryList = (gymData) => {
    // Destructure the necessary lists from gymData
    const { equipmentList = {}, facilities = {}, classes = {} } = gymData;
  
    // Filter the keys where the value is true for each list and sort them alphabetically
    const availableEquipment = Object.keys(equipmentList)
        .filter(key => equipmentList[key] === true)
        .sort(); // Sort alphabetically

    const availableFacilities = Object.keys(facilities)
        .filter(key => facilities[key] === true)
        .sort(); // Sort alphabetically

    const availableClasses = Object.keys(classes)
        .filter(key => classes[key] === true)
        .sort(); // Sort alphabetically
  
    // Return an object containing the filtered and sorted lists
    return {
        availableEquipment,
        availableFacilities,
        availableClasses,
    };
};


/**
 * Returns the 5 most important facilities of the gym
 * @param 	{Array}	facilities	-	List of facilities of the gym
 * @returns {Array}				-	Ordered list with the 5 main facilities at the top
 */
export const topFacilities = (facilities) => {

    // Array to store the selected top 5 facilities
    const selectedFacilities = [];

    // Helper function to compare facilities
    const formattedFacilities = facilities.map(facility => removeSpacesAndConvertLowercase(facility));

    // Loop through important facilities and check if they exist in the gym facilities
    for (let i = 0; i < importantFacilities.length; i++) {
        if (formattedFacilities.includes(importantFacilities[i])) {
            selectedFacilities.push(facilities[formattedFacilities.indexOf(importantFacilities[i])]);
        }
        // Break if we have found 5 facilities
        if (selectedFacilities.length === 5) break;
    }

    // Find the remaining facilities that are not part of the top 5
    const remainingFacilities = facilities.filter(facility => !selectedFacilities.includes(facility));

    // Return the combined array with top 5 facilities followed by the remaining ones
    return [...selectedFacilities, ...remainingFacilities];
}

/**
 * Segregates the equipment based on predefined categories.
 * If the equipment doesn't match any predefined category, it is added to the "special" category.
 *
 * @param {Array} equipmentList - List of all gym equipment.
 * @returns {Object} - Categorized equipment, with each category as a key and an array of matching equipment as the value.
 */
export const segregateEquipment = (equipmentList) => {
    // Initialize an object with empty arrays for each category
    const categorizedEquipment = {
        chest: [],
        back: [],
        arms: [],
        shoulders: [],
        legs: [],
        cardio: [],
        common: [],
        special: []
    };

    // Loop through the provided equipment list
    equipmentList.forEach(equipment => {
        let matched = false;

        // Convert equipment name to lowercase and remove spaces for comparison
        const formattedEquipment = removeSpacesAndConvertLowercase(equipment);

        // Check each category in the equipmentCategoryList
        Object.keys(equipmentCategoryList).forEach(category => {
            // Check if the formatted equipment matches any item in the category list
            if (equipmentCategoryList[category].includes(formattedEquipment)) {
                categorizedEquipment[category].push(equipment); // Use original name for display
                matched = true;
            }
        });

        // If no category matched, add to 'special'
        if (!matched) {
            categorizedEquipment.special.push(equipment);
        }
    });

    return categorizedEquipment;
};


/**
 * Returns the 5 most important classes of the gym
 * @param   {Array} classes - List of classes of the gym
 * @returns {Array} - Ordered list with the 5 main classes at the top
 */
export const topClasses = (classes) => {

    // Array to store the selected top 5 classes
    const selectedClasses = [];

    // Helper function to compare classes
    const formattedClasses = classes.map(classItem => removeSpacesAndConvertLowercase(classItem));

    // Loop through important classes and check if they exist in the gym classes
    for (let i = 0; i < importantClasses.length; i++) {
        if (formattedClasses.includes(importantClasses[i])) {
            selectedClasses.push(classes[formattedClasses.indexOf(importantClasses[i])]);
        }
        // Break if we have found 5 classes
        if (selectedClasses.length === 5) break;
    }

    // Find the remaining classes that are not part of the top 5
    const remainingClasses = classes.filter(classItem => !selectedClasses.includes(classItem));

    // Return the combined array with top 5 classes followed by the remaining ones
    return [...selectedClasses, ...remainingClasses];
}
