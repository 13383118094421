import React, { useState } from 'react';
import Modal from 'react-modal';
import SignUpPopup from './SignUpModal';
import SignInPopup from './SignInModal';
// import icon from '../../assets/Website_content/TR_Website_content-01.svg';
import icon from '../../assets/CTA_01.png';

// /**
//  * SignUp/SignIn Modal chooser
//  * @param {boolean}     modalIsOpen     - State to control if the modal is open
//  * @param {function}    setModalIsOpen  - Function to set the modal open state
//  * @param {Object}      myData          - Data to be passed to SignUpPopup and SignInPopup
//  * @param {boolean}     showGuest       - State to control the dispay of "Continue as a Guest" button
//  */
// export const UserLoginModal = ({ modalIsOpen, setModalIsOpen, myData, showGuest = true }) => {
//     const [showSignUpPopup, setShowSignUpPopup] = useState(false);
//     const [showSignInPopup, setShowSignInPopup] = useState(false);

//     // Adding usertype to local storage as guest
//     const handleContinueAsGuest = () => {
//         localStorage.setItem('usertype', 'guest');
//         setModalIsOpen(false)
//     };;

//     return (
//         <>
//             <Modal
//                 isOpen={modalIsOpen}
//                 onRequestClose={() => setModalIsOpen(false)}
//                 contentLabel="Sign In or Sign up in seconds"
//                 className="fixed inset-0 flex items-center justify-center z-50 text-tertiary"
//                 overlayClassName="fixed z-50 inset-0 bg-logoGray bg-opacity-50"
//             >
//                 <div className="bg-secondary rounded-3xl shadow-lg max-w-[600px] max-h-[300px] relative flex p-4 lg:p-8">
//                     {/* Close button */}
//                     <button
//                         onClick={() => setModalIsOpen(false)}
//                         className="absolute top-4 right-4 text-tertiary hover:text-black transition text-4xl"
//                     >
//                         &times;
//                     </button>
//                     <div className="flex flex-col lg:flex-row">
//                         {/* Left segment with logo */}
//                         <div className="flex items-center justify-center p-8">
//                             <img src={icon} alt="Logo" className="object-contain shadow-none h-8" />
//                         </div>
//                         {/* Right segment with buttons */}
//                         <div className="flex flex-col items-center justify-center space-y-4 p-4">
//                             <button
//                                 onClick={() => handleSignIn(setModalIsOpen, setShowSignInPopup)}
//                                 className="bg-tertiary text-black px-4 py-2 rounded-full hover:bg-primary hover:text-tertiary transition w-full"
//                             >
//                                 Sign In
//                             </button>
//                             <button
//                                 onClick={() => handleSignUp(setModalIsOpen, setShowSignUpPopup)}
//                                 className="bg-tertiary text-black px-4 py-2 rounded-full hover:bg-primary hover:text-tertiary transition w-full"
//                             >
//                                 Sign Up
//                             </button>
//                             {
//                                 showGuest && (
//                                     <button
//                                         onClick={handleContinueAsGuest}
//                                         className="text-white px-4 rounded-full transition w-full"
//                                     >
//                                         Continue as Guest
//                                     </button>
//                                 )
//                             }
//                         </div>
//                     </div>
//                 </div>
//             </Modal>
//             {/* Conditionally render SignUpPopup */}
//             {showSignUpPopup && (
//                 <SignUpPopup onClose={() => setShowSignInPopup(false)} myData={myData} />
//             )}
//             {/* Conditionally render SignInPopup */}
//             {showSignInPopup && (
//                 <SignInPopup onClose={() => setShowSignInPopup(false)} myData={myData} />
//             )}
//         </>
//     );
// };

/**
 * Handles User Sign In
 * @param {function} setModalIsOpen - Function to set the modal open state
 * @param {function} setShowSignInPopup - Function to set the sign-in popup state
 */
const handleSignIn = (setModalIsOpen, setShowSignInPopup) => {
    // Add your sign in logic here
    setModalIsOpen(false);
    setShowSignInPopup(true);
};

/**
 * Handles User Sign Up
 * @param {function} setModalIsOpen - Function to set the modal open state
 * @param {function} setShowSignUpPopup - Function to set the sign-up popup state
 */
const handleSignUp = (setModalIsOpen, setShowSignUpPopup) => {
    // Add your sign up logic here
    setModalIsOpen(false);
    setShowSignUpPopup(true);
};

/**
 * SignUp/SignIn Modal chooser
 * @param {boolean}     modalIsOpen     - State to control if the modal is open
 * @param {function}    setModalIsOpen  - Function to set the modal open state
 * @param {Object}      myData          - Data to be passed to SignUpPopup and SignInPopup
 * @param {boolean}     showGuest       - State to control the display of "Continue as a Guest" button
 */
export const UserLoginModal = ({ modalIsOpen, setModalIsOpen, myData, showGuest = true }) => {
    const [showSignUpPopup, setShowSignUpPopup] = useState(false);
    const [showSignInPopup, setShowSignInPopup] = useState(false);

    // Adding usertype to local storage as guest
    const handleContinueAsGuest = () => {
        localStorage.setItem('usertype', 'guest');
        setModalIsOpen(false);
    };

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Sign In or Sign up in seconds"
                className="fixed inset-0 flex items-center justify-center z-50 text-tertiary"
                overlayClassName="fixed z-50 inset-0 bg-logoGray bg-opacity-60"
            >
                <div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] md:max-w-[85%] lg:max-w-[52%] max-h-[600px] relative flex flex-col md:flex-row p-10 lg:p-14">
                    {/* Left segment with the image occupying 2/3 of the modal */}
                    <div className="w-full md:w-2/3 flex items-center justify-center p-0 lg:p-8 rounded-l-3xl">
                        <img src={icon} alt="Logo" className="object-cover w-full h-full" />
                        {/* <img src={icon} alt="Logo" className="object-contain shadow-none h-full" /> */}
                    </div>

                    {/* Right segment with buttons occupying 1/3 of the modal */}
                    <div className="w-full md:w-1/3 flex flex-col items-center justify-center -mt-6 md:mt-0 space-y-4 p-6">
                        <button
                            onClick={() => handleSignIn(setModalIsOpen, setShowSignInPopup)}
                            className="bg-tertiary text-black text-sm lg:text-base px-4 lg:px-6 py-2 lg:py-3 rounded-full hover:bg-primary hover:text-tertiary transition-all duration-300  hover:scale-105 w-full"
                        >
                            Sign In
                        </button>
                        <button
                            onClick={() => handleSignUp(setModalIsOpen, setShowSignUpPopup)}
                            className="bg-tertiary text-black text-sm lg:text-base px-4 lg:px-6 py-2 lg:py-3 rounded-full hover:bg-primary hover:text-tertiary transition-all duration-300  hover:scale-110 w-full"
                        >
                            Sign Up
                        </button>
                        {showGuest && (
                            <button
                                onClick={handleContinueAsGuest}
                                className="text-tertiary bg-transparent text-xs lg:text-base px-4 lg:px-6 py-2 lg:py-3 rounded-full transition-all duration-300 hover:scale-105 w-full hover:text-primary"
                            >
                                Continue as Guest
                            </button>
                        )}
                    </div>

                    {/* Close button */}
                    <button
                        onClick={() => setModalIsOpen(false)}
                        className="absolute top-2 md:top-4 right-8 text-tertiary hover:text-primary transition text-4xl"
                    >
                        &times;
                    </button>
                </div>
            </Modal>

            {/* Conditionally render SignUpPopup */}
            {showSignUpPopup && (
                <SignUpPopup onClose={() => setShowSignUpPopup(false)} myData={myData} switchModal={() => {setShowSignUpPopup(false); setShowSignInPopup(true);}}/>
            )}
            {/* Conditionally render SignInPopup */}
            {showSignInPopup && (
                <SignInPopup onClose={() => setShowSignInPopup(false)} myData={myData} switchModal={() => {setShowSignInPopup(false); setShowSignUpPopup(true);}} />
            )}
        </>
    );
};
