import React from 'react';

/**
 * SelectedCoupon
 * Displays the selected coupon exactly like the provided design, using Tailwind CSS.
 * 
 * @param {object} coupon - The selected coupon object.
 * @returns {JSX.Element} The coupon display component.
 */
const SelectedCoupon = ({ selected, coupon }) => {
    if (!coupon) return null; // Return nothing if no coupon is selected

    // Determine if the coupon is percentage or fixed value, if coupon is not selected then we have to show the default banner which has percentage
    let isPercentage;
    if (selected) {
        isPercentage = coupon.type === 'percentage';
    } else {
        isPercentage = true;
    }

    return (
        <div className="flex justify-between items-center p-4 md:p-8 bg-tertiary rounded-xl shadow-lg w-full h-full shadow-black" style={{ fontFamily: 'Helvetica, Arial, sans-serif', boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.25)' }}>
            {/* Left Side - Coupon Code and Description */}
            <div className="flex flex-col md:gap-1 lg:gap-4 text-left min-w-6/12 ">
                {/* Display the coupon code in secondary color */}
                <h2 className="text-[16px] md:text-[26px] lg:text-[55px] font-bold text-secondary md:leading-[32px] lg:leading-[60px]">  {/* Leading is the h2 tag's total height allowance */}
                    {selected ? coupon.code : (
                        <>REFER <br /> A FRIEND</>
                    )}
                </h2>
                {/* Display the coupon description in primary color and bold */}
                <p className="text-xs md:text-base lg:text-[21px] font-bold text-primary">
                    {selected ? coupon.description : (
                        <>and you'll both receive <br /> 10% off on your next order!</>
                    )}
                </p>
            </div>


            {/* Vertical Line separating the left and right sides */}
            <div className={`border-l-2 border-secondary h-16 md:h-24 lg:h-44 mx-2`}></div>

            
            {/* Right Side - Discount Display */}
            <div className="flex items-center space-x-2 text-secondary justify-end">
                {/* If the coupon is a percentage, display percentage value, otherwise fixed amount */}
                <h2 className={`text-[50px] md:text-[120px] lg:text-[200px] font-bold`} style={{ textShadow: '2px 2px gray' }}>
                    {isPercentage ? `${selected ? coupon.value : 10}` : `${coupon.value}`}
                </h2>
                <div className="flex flex-col items-start text-4xl">
                    <h2 className="font-bold text-2xl md:text-7xl lg:text-8xl" style={{ textShadow: '2px 2px gray' }}>
                        {isPercentage ? '%' : ''}
                    </h2>
                    <p className="font-bold text-sm md:text-3xl lg:text-4xl" style={{ textShadow: '2px 2px gray' }}>OFF</p>
                </div>
                {selected === "notSelected" && <div className='text-2xl italic'>Use Code: Buddy10</div>}
            </div>
        </div>
    );
};


export default SelectedCoupon;
