import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import '../../App.css';
import { NearYou } from '../../helperFunctions/MarketplaceHelpers/NearYou';
import { themeColors } from '../../theme';
import { removeSpacesAndConvertLowercase } from '../../helperFunctions/basicHelper';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase';

function BlogPost({ selectedCity }) {
    const location = useLocation();
    const props = location.state || {};  //  Getting Data from Near You

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Marketplace BlogPost', {
            page_path: location.pathname,
        });
    }, [location]);

    return (
        <>
            {/* SEO: Dynamic Helmet */}
            <Helmet>
                <title>{props.gymDisplayName ? `Explore ${props.gymDisplayName} in ${selectedCity} | Train Rex Blog` : "Train Rex Blog"}</title>
                <meta 
                    name="description" 
                    content={props.content ? props.content.slice(0, 150) : `Explore gyms and fitness centers near you in ${selectedCity} and stay fit with Train Rex.`} 
                />
                <meta 
                    name="keywords" 
                    content={`gyms in ${selectedCity}, ${props.gymDisplayName}, fitness classes, personal trainers, membership plans`} 
                />
                <link rel="canonical" href={`https://trainrexofficial.com/blog/${props.gymDisplayName ? removeSpacesAndConvertLowercase(props.gymDisplayName) : 'post'}`} />
            </Helmet>

            <div className="bg-tertiary flex flex-col space-y-8 pb-8 lg:pb-16 w-auto pt-28 lg:pt-40">
                <div className="px-8 lg:px-48">
                    {/* Heading and Name */}
                    <div className="flex flex-col space-y-2">
                        <h1 className="text-2xl lg:text-4xl font-bold text-secondary text-left">{props.gymDisplayName}</h1>
                        <p className="text-lg lg:text-xl font-bold text-secondary text-left">{props.locality}</p>
                    </div>

                    {/* Image Section */}
                    {props.image !== null && 
                        <div className="flex justify-center items-center pb-8 pt-4">
                            <div className="w-full h-full bg-tertiary border-4 border-secondary rounded-2xl overflow-hidden max-h-[50vh] mt-4">
                                <img 
                                    src={props.image} 
                                    alt="Logo" 
                                    className="w-full h-auto object-fit" 
                                    style={{ maxHeight: '50vh' }} 
                                />
                            </div>
                        </div>
                    }

                    {/* Blog Content */}
                    <div className="rounded-xl text-left" dangerouslySetInnerHTML={{ __html: props.content }} /> 
                </div>
                
                {/* Gym List Section */}
                <NearYou 
                    heading='CHECK OUT FITNESS CENTRES NEAR YOU'
                    headingMobile='FITNESS CENTRES NEAR YOU'
                    bg='bg-tertiary w-auto'
                    size='lg:px-40'
                    headingColor='text-secondary'
                    locationIconColor={themeColors.lightAccent}
                    selectedCity={removeSpacesAndConvertLowercase(selectedCity)}
                />
            </div>
        </>
    );
}

export default BlogPost;
