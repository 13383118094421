import React from 'react';

/**
 * PaymentLoadingModal
 * 
 * A modal that displays a loading animation and a message while the payment is being processed.
 * The modal is shown only if the `isOpen` prop is true.
 * 
 * @param {boolean} isOpen - Determines whether the modal should be displayed or not.
 * 
 * @returns {JSX.Element|null} The modal element or null if `isOpen` is false.
 */
const PaymentLoadingModal = ({ isOpen }) => {
    // If the modal is not open, return null to prevent rendering
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="flex flex-col items-center bg-white p-8 sm:p-10 rounded-xl shadow-xl text-center space-y-6">
                {/* Spinning loader animation */}
                <div className="loader border-t-transparent border-4 border-secondary rounded-full w-16 h-16 animate-spin"></div>
                
                {/* Loading message */}
                <p className="text-xl font-semibold">Processing your payment...</p>
            </div>
        </div>
    );
};

export default PaymentLoadingModal;
