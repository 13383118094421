import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet"; // Import Leaflet library
import "leaflet/dist/leaflet.css";
import TR_locationicon from '../../../assets/TR_locationicon.svg'; // Updated to use the SVG icon
import { fetchAllGyms } from "../MarketplaceHelper";
import { getMapLocations } from "./MapHelper";
import { Link } from "react-router-dom";
import TRLogo from "../../../assets/Icon-512.png";
import StarRating from "../../../components/StarRating";
import { LocationIcon } from "../../../assets/WelcomeScreenImage";
import { getFinalImage } from "../ButtonsLibrary";

// Create a custom icon using the SVG
const customIcon = L.icon({
    iconUrl: TR_locationicon, // Path to the SVG marker image
    iconSize: [40, 40], // Size of the icon (adjust as needed)
    iconAnchor: [20, 40], // Adjust anchor point of the icon
    popupAnchor: [0, -40], // Popup position relative to the icon
});

const Map = ({ selectedCity, currentPage }) => {
    const [mapLocations    , setMapLocations    ] = useState([]);
    const [loading         , setLoading         ] = useState(true);
    const [selectedLocation, setSelectedLocation] = useState(null); 
    const [fadeIn          , setFadeIn          ] = useState(false); // New state for opacity

    useEffect(() => {
        const fetchGymsAndLocations = async () => {
            try {
                const gymData = await fetchAllGyms(selectedCity);
                const mapData = await getMapLocations(gymData); // Fetch gyms and locations
                setMapLocations(mapData);
            } catch (error) {
                // console.error("Error fetching gym data or locations:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchGymsAndLocations();
    }, [selectedCity]);

    // Trigger opacity change only when a new location is selected
     useEffect(() => {
        if (selectedLocation) {
            console.log(selectedLocation.image[0]);
            setFadeIn(true); // Set opacity to fade in
        }
    }, [selectedLocation]);

    if (loading) {
        return <div>Loading map...</div>;
    }

    return (
        <div className={`flex ${currentPage === 'marketplace' ? 'flex-col lg:flex-row lg:h-[710px] justify-between gap-4 px-4 py-4' : 'flex-row h-[300px] lg:h-[540px] justify-center py-8 bg-transparent'}  bg-secondary`}>
            {/* Map Section */}
            <div className={`${currentPage === 'marketplace' ? 'w-full lg:w-2/3 h-64 md:h-80 lg:h-full border-4 rounded-2xl overflow-hidden border-primary' : '-ml-7 lg:-ml-0 w-[95%] lg:w-[79%] rounded-3xl overflow-hidden shadow-xl'} z-10`}>
                <MapContainer
                    className="h-full w-full z-0"
                    center={[28.651181049999998, 77.29717993562505]} // Default center
                    zoom={12}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {mapLocations.map((loc, index) => (
                        currentPage === "marketplace" ? (
                            <Marker
                                key={index}
                                position={[loc.lat, loc.lng]}
                                icon={customIcon} // Use the custom SVG icon here
                                eventHandlers={{
                                    click: () => {
                                        if (selectedLocation !== loc) {
                                            setFadeIn(false);
                                            setSelectedLocation(loc);
                                        }
                                    },
                                }}
                            />
                        ) : (
                            <Marker
                                key={index}
                                position={[loc.lat, loc.lng]}
                                icon={customIcon}
                                eventHandlers={{
                                    click: () => {
                                        if (selectedLocation !== loc) {
                                            setFadeIn(false);
                                            setSelectedLocation(loc);
                                        }
                                    },
                                }}
                            >
                                {/* Display a popup with the gym name */}
                                <Popup>
                                    <div>{loc.name}</div> {/* Replace with actual gym name field */}
                                </Popup>
                            </Marker>
                        )
                    ))}
                </MapContainer>
            </div>

            {/* Details Section */}
            {currentPage === "marketplace" && <div className={`w-[98%] lg:w-1/3 flex ${selectedLocation ? 'items-start' : 'items-end'} justify-between lg:p-4 h-full transition-opacity`}>
                {selectedLocation ? (
                    <div className={`flex flex-col justify-between w-full ${fadeIn ? 'duration-1000' : 'duration-0' } ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                        {/* Gym Info */}
                        <div className="flex flex-col gap-4"> 	
                            <img 
                                src={getFinalImage(selectedLocation.image[0]) || TRLogo} 
                                alt="Gym Image" 
                                className="rounded-2xl max-h-52 lg:max-h-[320px] min-w-[102%] object-cover" 
                            />
                            <div className="flex justify-between items-center w-full"> 
                                <div className="text-left font-bold text-2xl lg:text-4xl text-tertiary w-7/12">
                                    {selectedLocation.name}
                                </div>
                                <div className="text-lg w-20 lg:w-32 rounded-md font-bold scale-150 -ml-10 pr-3 lg:pr-5">
                                    <StarRating rating={selectedLocation.rating} color="primary" showNoOfReviews={false}/>
                                </div>
                            </div>
                            <div className="text-tertiary flex items-center gap-1">
                                <LocationIcon className="w-5 lg:w-7 h-4 lg:h-6" color="#f6faea"/>
                                <p className="text-sm lg:text-xl text-tertiary font-bold">
                                    {selectedLocation?.locality ? selectedLocation.locality : 'Locality'}
                                </p>
                            </div>
                            <p className="text-lg lg:text-2xl font-semibold text-primary mb-2 text-left">
                                {selectedLocation.address}
                            </p>
                        </div>

                        {/* Buy Membership/Explore button and Prices */}
                        <div className="flex flex-col items-start justify-between space-y-4">
                            <div className="flex items-end space-x-4">
                                <div className="text-primary line-through text-sm lg:text-lg">
                                    ₹{selectedLocation.mrp ? selectedLocation.mrp : " "}/Year
                                </div>
                                <div className="font-bold text-xl lg:text-3xl text-tertiary">
                                    ₹{selectedLocation.exclusivePrice ? selectedLocation.exclusivePrice : " "}/Year
                                </div>
                            </div>
                            <Link
                                to={`${selectedLocation.city}/gyms/${selectedLocation.gymName}`}
                                className="bg-tertiary rounded-xl py-2 px-4 text-primary font-semibold hover:scale-105 hover:shadow-primary hover:shadow-2xl transition-all duration-300"
                            >
                                Buy Membership
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className={`text-left text-lg lg:text-2xl font-semibold text-primary pb-4 ${selectedLocation ? 'opacity-0' : 'opacity-100'}`}>
                        <div className="text-2xl lg:text-6xl font-bold text-tertiary mb-1 md:mb-2">Hey Buddy!</div>
                        <span className=""> Wanna Explore Gyms in your Area? <br /> Select any Gym from the Map and that's it!</span>
                    </div>
                )}
            </div>}
        </div>
    );
};

export default Map;