import { useEffect, useState, useRef } from "react";
import { fetchAllBlogs } from "./MarketplaceHelper";
import { useNavigate } from 'react-router-dom';
import { removeSpacesAndConvertLowercase } from "../basicHelper";

export const Blogposts = (props) => {
    const [blogList, setBlogList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const slideIntervalRef = useRef(null); // Ref to store the interval ID

    useEffect(() => {
        setLoading(true);
        const bloglist = async () => {
            const blogsList = await fetchAllBlogs();
            setBlogList(blogsList);
            setLoading(false);
        };
        bloglist();
    }, [props.selectedCity]);

    useEffect(() => {
        if (!loading) console.log(blogList);
    }, [loading, blogList]);

    useEffect(() => {
        startSlideTimer();

        return () => clearInterval(slideIntervalRef.current); // Cleanup interval on unmount
    }, [blogList.length]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const startSlideTimer = () => {
        clearInterval(slideIntervalRef.current); // Clear any existing interval
        slideIntervalRef.current = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % blogList.length);
        }, 7000); // Change slide every 7 seconds
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % blogList.length);
        startSlideTimer(); // Reset timer after manual slide change
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + blogList.length) % blogList.length);
        startSlideTimer(); // Reset timer after manual slide change
    };

    const navigate = useNavigate();

    const handleNavigate = (blog) => {
        const blogSlug = removeSpacesAndConvertLowercase(blog.gymDisplayName);
        navigate(`/blogs/${blogSlug}`, { state: blog }); // Pass the blog data to BlogPost via state
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!blogList || blogList.length === 0) {
        return <div>No blogs available</div>;
    }

    const truncateContent = (content) => {
        let length;
        if (screenWidth <= 640) {
            length = 150;
        } else if (screenWidth <= 1024) {
            length = 195;
        } else {
            length = 240;
        }
        return content.length > length ? content.substring(0, length) + "..." : content;
    };

    return (
        <div className="p-8 bg-darkTertiary min-h-[400px] md:min-h-[50vh] md:max-h-[50vh] lg:min-h-[60vh] lg:max-h-[60vh] relative overflow-hidden mt-10">
            <div className="absolute inset-0 flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {blogList.map((blog, index) => {
                    const content = stripHtml(blog.content);
                    const truncatedContent = truncateContent(content);

                    return (
                        <div
                            key={index}
                            className="flex flex-col lg:flex-row justify-center gap-4 lg:gap-x-4 min-w-full  lg:px-16"
                            onClick={() => handleNavigate(blog)} // Add this onClick handler
                        >
                            <div className="flex flex-[3] justify-center items-center overflow-hidden mt-3 mx-3 lg:ml-12 lg:mr-3 lg:mt-0">
                                <img
                                    src={convertToDirectLink(blog.image[0])}
                                    alt="Logo"
                                    className="w-[75vw] md:w-[70vw] md:h-[50vh] object-cover rounded-2xl lg:rounded-3xl"
                                />
                            </div>
                            <div className="flex flex-[2] mb-9 flex-col justify-end items-start lg:mb-10 px-3">
                                <h3 className="text-start text-secondary text-lg md:text-xl lg:text-5xl font-bold lg:font-semibold">
                                    {blog.gymDisplayName}
                                </h3>
                                <div className="text-start text-secondary text-base md:text-lg lg:text-2xl font-semibold mt-3">
                                    By {blog.locality}
                                </div>
                                <div className="text-start text-secondary text-sm md:text-base lg:text-1xl font-semibold mt-5">
                                    {truncatedContent}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* Left Arrow */}
            <button
                className="absolute block bottom-1/2 transform -translate-y-1/2 left-4 text-xl md:text-3xl lg:text-6xl text-secondary lg:hover:text-tertiary lg:hover:bg-primary lg:hover:bg-opacity-35 transition-all duration-700 px-[20px] pb-3 rounded-full"
                onClick={prevSlide}
            >
                &lt; {/* Left arrow pointing left */}
            </button>

            {/* Right Arrow */}
            <button
                className="absolute block bottom-1/2 transform -translate-y-1/2 right-4 text-xl md:text-3xl lg:text-6xl text-secondary lg:hover:text-tertiary lg:hover:bg-primary lg:hover:bg-opacity-35 transition-all duration-700 px-[20px] pb-3 rounded-full"
                onClick={nextSlide}
            >
                &gt; {/* Right arrow pointing right */}
            </button>


            {/* Circles indicating the current slide */}
            <div className="absolute pb-1 bottom-1 md:bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {blogList.map((_, index) => (
                    <div
                        key={index}
                        className={`w-2 h-2 rounded-full ${currentSlide === index ? 'bg-secondary' : 'bg-gray-400'}`}
                        style={{ transition: 'background-color 0.3s ease' }}
                    ></div>
                ))}
            </div>
        </div>
    );
};

const convertToDirectLink = (url) => {
    const fileIdMatch = url.match(/\/d\/([a-zA-Z0-9_-]+)\//);
    return fileIdMatch !== null ? `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}` : url;
};

const stripHtml = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};
