import { useState } from 'react';
import { doc, updateDoc, arrayUnion, collection, addDoc, increment, getDoc } from '@firebase/firestore'; // Firebase functions for database update
import { db } from '../config/firebase' // Import your Firestore config
import { cityList, gymList, reviewList } from '../components/Databases';


const AddReviewModal = ({ setShowReviewModal, gymLocation, gymName, isRecentGymUser, displayName }) => {
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const [submitting, setSubmitting] = useState(false);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();


        setSubmitting(true);
        try {
            // Path: cityList > gym.location > gyms > gym.gymName > Reviews
            const gymReftoAddReview = collection(db, cityList, gymLocation, gymList, gymName, reviewList);
            const gymRefForReviewsCount = doc(db, cityList, gymLocation, gymList, gymName);


            if (rating < 1) {
                alert("Please give the gym a rating");
                setSubmitting(false);
                return;
            }


            // Add review, with 'verified' set based on isRecentGymUser
            await addDoc(gymReftoAddReview, {
                userName        : displayName,
                rating          : rating,
                review          : reviewText,
                timestamp       : new Date().toISOString(), // Add timestamp for reference
                verified        : isRecentGymUser ? true : false, // Set 'verified' field
                gymName         : gymName,
            });


            // Update the total number of reviews and sum of ratings
            await updateDoc(gymRefForReviewsCount, {
                NoOfReview      : increment(1),             // Increment the total number of reviews by 1
                SumOfRatings    : increment(rating),       // Increment the sum of all ratings by the current rating
            });


            // Fetch the updated gym data to calculate the average rating
            const gymSnapshot = await getDoc(gymRefForReviewsCount);
            if (gymSnapshot.exists()) {
                const gymData = gymSnapshot.data();
                const newNoOfReviews = gymData.NoOfReview;
                const newSumOfRatings = gymData.SumOfRatings;


                // Calculate the new average rating
                const newAvgRating = newSumOfRatings / newNoOfReviews;

                // Update the gym's average rating
                await updateDoc(gymRefForReviewsCount, {
                    rating: newAvgRating,               // Set the new average rating
                });
            }


            alert("Review added successfully");
            setShowReviewModal(false); // Close the modal on successful submission


        } catch (error) {
            // console.error("Error adding review: ", error);
        }
        setSubmitting(false);
    };


    return (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-tertiary p-6 rounded-lg w-96">
                <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold mb-4 text-primary">Add a Review</h2>


                    <label className="block mb-2 text-secondary text-xl font-semibold">Your Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)} // This will be ignored because the field is disabled
                        className="border-4 border-secondary rounded-xl w-full p-2 mb-4 font-bold bg-tertiary"
                        placeholder={displayName}
                        readOnly // Make the input read-only
                        required
                    />


                    <label className="block mb-2 text-secondary text-xl font-semibold">Rating</label>
                    <select
                        value={rating}
                        onChange={(e) => setRating(Number(e.target.value))}
                        className="border-4 border-secondary bg-tertiary rounded-xl w-full p-2 mb-4"
                        required
                    >
                        <option value={0}>Select rating</option>
                        <option value={1}>1 Star</option>
                        <option value={2}>2 Stars</option>
                        <option value={3}>3 Stars</option>
                        <option value={4}>4 Stars</option>
                        <option value={5}>5 Stars</option>
                    </select>


                    <label className="block mb-2 text-secondary text-xl font-semibold">Review</label>
                    <textarea
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                        className="border-4 border-secondary rounded-xl w-full p-2 mb-4 bg-tertiary"
                        placeholder="Write your review"
                        required
                    />


                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={() => setShowReviewModal(false)}
                            className="bg-primary text-tertiary px-4 py-2 rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            type='submit'
                            className="bg-secondary text-tertiary px-4 py-2 rounded-md"
                            disabled={submitting}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};


export default AddReviewModal;