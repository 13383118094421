import React, { useState } from 'react';
import convertDriveUrl from '../imgUrlRecontuct';
import { Carousel } from "react-responsive-carousel";

const GymPortfolio = ({ currGymData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const visibleDots = 5; // Number of dots to show

    const openModal = (index) => {
        setSelectedImageIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Custom Indicator function
    const customIndicator = (onClickHandler, isSelected, index) => {
        const middleDot = Math.floor(visibleDots / 2);
        const startRange = Math.max(0, selectedImageIndex - middleDot);
        const endRange = Math.min(currGymData.Portfolio.length - 1, selectedImageIndex + middleDot);

        // Only display dots within the calculated range
        if (index >= startRange && index <= endRange) {
            const isLeftDot = index < selectedImageIndex;
            const isRightDot = index > selectedImageIndex;
            const distanceFromCenter = Math.abs(index - selectedImageIndex);
            const dotSize = distanceFromCenter === 0 ? 'h-3 w-3' : distanceFromCenter === 1 ? 'h-2.5 w-2.5' : 'h-2 w-2';

            return (
                <li
                    className={`${isSelected ? 'bg-secondary' : 'bg-gray-200'} ${dotSize} mx-1 rounded-2xl my-4`}
                    onClick={onClickHandler}
                    key={index}
                    role="button"
                    aria-label={`Slide ${index + 1}`}
                    style={{ display: 'inline-block', transform: 'rotate(180deg)' }} // Rotate the dot
                />
            );
        }
        return null;
    };

    // Handler to update the selected index when the carousel changes
    const handleCarouselChange = (index) => {
        setSelectedImageIndex(index); // Update selected image index
    };

    return (
        <>
            {Array.isArray(currGymData.Portfolio) && (
                <div className="flex flex-row h-96 bg-secondary border-4 border-secondary rounded-2xl">
                    {currGymData.Portfolio.length > 0 ? (
                        <div className="relative flex h-full lg:h-84 w-full border-2 border-secondary rounded-2xl overflow-hidden">
                            <img
                                src={convertDriveUrl(currGymData.Portfolio[0])}
                                alt="Logo"
                                className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                onClick={() => openModal(0)}
                            />
                            <div className="absolute bottom-0 right-0 bg-secondary bg-opacity-100 rounded-xl lg:hidden flex justify-between p-2">
                                <p className="flex text-tertiary text-xl font-bold">
                                    + {currGymData.Portfolio.length} photos
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-row h-96 bg-secondary border-4 border-secondary rounded-2xl">
                            No Images Available
                        </div>
                    )}

                    {/* Display images in a grid for larger screens */}
                    {currGymData.Portfolio.length > 1 && (
                        <div className="hidden lg:flex flex-col border-2 border-secondary rounded-2xl w-full">
                            <div className="flex h-56 overflow-hidden">
                                {currGymData.Portfolio.slice(1, 2).map((image, index) => (
                                    <img
                                        key={index}
                                        src={convertDriveUrl(image)}
                                        alt="Portfolio Image"
                                        className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                        onClick={() => openModal(index + 1)}
                                    />
                                ))}
                            </div>
                            <div className="flex flex-row w-full">
                                {currGymData.Portfolio.slice(2, 4).map((image, index) => (
                                    <div className="flex h-36 w-full overflow-hidden" key={index}>
                                        <img
                                            src={convertDriveUrl(image)}
                                            alt="Portfolio Image"
                                            className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                            onClick={() => openModal(index + 2)}
                                        />
                                    </div>
                                ))}
                                {currGymData.Portfolio.length > 4 && (
                                    <div className="relative flex h-36 w-full overflow-hidden">
                                        <img
                                            src={convertDriveUrl(currGymData.Portfolio[3])}
                                            alt="Logo"
                                            className="w-full h-full object-cover rounded-2xl border-4 border-secondary cursor-pointer"
                                            onClick={() => openModal(3)}
                                        />
                                        <div className="absolute bottom-0 right-0 bg-secondary bg-opacity-100 rounded-xl hidden lg:flex justify-between p-2">
                                            <p className="flex text-tertiary text-xl font-bold">
                                                + {currGymData.Portfolio.length - 4} photos
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {isModalOpen && (
                        <div className="flex inset-0 fixed justify-center bg-black bg-opacity-50 z-50 pt-36 lg:p-64 mb-auto lg:-mt-48">
                            <div className="relative p-4 rounded-xl">
                                <button
                                    className="absolute -top-5 lg:-top-9 right-4 lg:right-4 text-tertiary text-4xl lg:text-6xl h-8"
                                    onClick={closeModal}
                                >
                                    &times;
                                </button>
                                <div className="overflow-hidden">
                                    {currGymData.Portfolio && (
                                        <Carousel
                                            selectedItem={selectedImageIndex}
                                            showThumbs={false}
                                            showStatus={false}
                                            renderIndicator={customIndicator}
                                            onChange={handleCarouselChange}
                                            className="object-cover items-center w-[300px] lg:w-full h-[800px]"
                                        >
                                            {currGymData.Portfolio.map((img, index) => (
                                                <div key={index}>
                                                    <img 
                                                        src={convertDriveUrl(img)} 
                                                        alt={`Image ${index}`} 
                                                        className='max-h-[800px] max-w-[500px] lg:max-w-full object-contain rounded-2xl shadow-lg'
                                                    />
                                                </div>
                                            ))}
                                        </Carousel>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default GymPortfolio;
