import React, { useState } from 'react';
import '../../App.css';
import { analytics, db, storage } from '../../config/firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import RichTextEditor from '../../components/RichTextEditor';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function WriteBlog() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [images, setImages] = useState([]); // For selected images
  const [imageUrls, setImageUrls] = useState([]); // For uploaded image URLs

  const location = useLocation();

  useEffect(() => {
      // Log page view event when route changes
      logEvent(analytics, 'Marketplace', {
          page_path: location.pathname,
      });
  }, [location]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    if (files.length) {
      setImages(files);
    }
  };

  const uploadImages = async () => {
    const uploadedUrls = [];

    for (const image of images) {
      const storageRef = ref(storage, `images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      const imageUrl = await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Optionally handle progress, pause, and resume here
          },
          (error) => {
            // console.error('Image upload failed:', error);
            reject(error);
          },
          async () => {
            const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadUrl);
          }
        );
      });

      uploadedUrls.push(imageUrl); // Add each uploaded image URL to the array
    }

    return uploadedUrls; // Return the array of URLs
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let uploadedImageUrls = [];

      if (images.length > 0) {
        // Upload all selected images
        uploadedImageUrls = await uploadImages();
        setImageUrls(uploadedImageUrls); // Update state with uploaded URLs
      }

      // Save blog post to Firestore
      const blogRef = collection(db, 'blogs');
      await addDoc(blogRef, {
        title,
        content,
        author,
        images: uploadedImageUrls.length > 0 ? uploadedImageUrls : null,
        timestamp: serverTimestamp(),
      });

      setTitle('');
      setContent('');
      setAuthor('');
      setImages([]);
      setImageUrls([]);
      alert('Blog submitted successfully!');
    } catch (error) {
      // console.error('Error writing document: ', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center pb-8 bg-tertiary min-h-screen items-left gap-y-8">
      <div className="flex items-left px-16">
        <label>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div>
        <label className="flex item-left px-16">Content:</label>
        <div className="">
          <RichTextEditor setContent={setContent} />
        </div>
      </div>
      <div className="flex items-left px-16">
        <label>Author:</label>
        <input
          type="text"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          required
        />
      </div>
      <div className="flex items-left px-16">
        <label>Upload Images:</label>
        <input type="file" multiple onChange={handleImageUpload} />
      </div>
      <button className="flex items-left px-16" type="submit">Submit</button>
    </form>
  );
}

export default WriteBlog;
