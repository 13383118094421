import React, { useEffect, useRef, useState } from "react";
import iphone_home from "../../../assets/Website_content/iphone_home.png";
import iphone_appstore from "../../../assets/Website_content/iphone_appstore.png";
import { AppStore, PlayStore } from "../../../assets/WelcomeScreenImage";

export const GetTheApp = () => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const componentRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const component      = componentRef.current;
            const rect           = component.getBoundingClientRect();
            const scrollTop      = window.scrollY || document.documentElement.scrollTop;
            const elementTop     = rect.top + scrollTop;
            const elementHeight  = rect.height;
            const viewportHeight = window.innerHeight;

            // Calculate scroll progress as a value between 0 (top of viewport) and 1 (bottom of viewport)
            const scrollProgress = Math.min(
                Math.max((scrollTop - elementTop + viewportHeight) / (elementHeight + viewportHeight), 0),
                1
            );

            setScrollProgress(scrollProgress);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Animation style for the iphone_appstore image
    const appStoreImageStyle = {
        transform: scrollProgress < 0.23 ? `scale(${1 + scrollProgress * 3})` : `scale(${1 + 0.23 * 3})`, // Scale as scroll progresses
        transition: "transform 0.5s ease-out",
    };

    // Animation style for the iphone_home image
    const homeImageStyle = {
        transform: scrollProgress < 0.418 ? `scale(${scrollProgress * 2.8 }) translateX(${scrollProgress}px)` : `scale(${0.418 * 2.8 }) translateX(${0.418}px)`, // Scale and slide to the left
        transition: "transform 0.5s ease-out",
    };

    // Fade-in for the "GET THE TRAIN REX APP!" text from left
    const headingStyle = {
        opacity: scrollProgress > 0.28 ? 1 : 0, // Fade in once 50% of the component is visible
        transform: scrollProgress < 0.46 ? `translateX(${(1 - scrollProgress * 2) * -100}px)` : `translateX(${(1 - 0.46 * 2) * -100}px)`, // Move from left to right
        transition: "opacity 1s ease-out, transform 1s ease-out",
    };

    // console.log("Scroll progress", scrollProgress)

    return (
        <div
            ref={componentRef}
            className="flex flex-col md:flex-row min-h-[80vh] md:gap-10 lg:gap-52 lg:justify-center bg-darkTertiary p-6 lg:py-20"
        >
            {/* the image */}
            <div className="flex justify-center items-center scale-75 md:scale-100 lg:scale-150">
                <img
                    src={iphone_appstore}
                    alt="iphone_appstore"
                    className="h-60 w-30 z-0"
                    style={appStoreImageStyle}
                />
                <img
                    src={iphone_home}
                    alt="iphone_home"
                    className="-ml-16 h-96 w-48 z-10"
                    style={homeImageStyle}
                />
            </div>

            {/* the texts */}
            <div className="flex justify-center items-center flex-col">
                {/* heading */}
                <div
                    className="font-bold text-3xl lg:text-6xl text-secondary"
                    style={headingStyle}
                >
                    GET THE TRAIN REX APP!
                </div>

                {/* slogan */}
                <div
                    className="font-semibold text-base lg:text-3xl text-secondary mt-5"
                    style={{
                        opacity: scrollProgress > 0.318 ? 1 : 0, // Fade in based on scroll position
                        transition: "opacity 1.5s ease-out",
                    }}
                >
                    MATCH. TRACK. CONSULT
                </div>

                {/* description */}
                <div
                    className="font-semibold text-xs lg:text-xl text-secondary mt-7"
                    style={{
                        opacity: scrollProgress > 0.34 ? 1 : 0, // Fade in
                        transition: "opacity 1.7s ease-out",
                    }}
                >
                    Your pocket-friendly personal trainer,
                    <br />
                    waiting to find you your people, track your progress
                    <br />
                    and provide personal guidance,
                    <br />
                    all based on your schedule.
                </div>

                {/* app buttons */}
                <div
                    className="flex flex-row justify-between lg:gap-x-6 mt-7"
                    style={{
                        opacity: scrollProgress > 0.4 ? 1 : 0, // Fade in
                        transition: "opacity 2s ease-out",
                    }}
                >
                    <a
                        href="https://apps.apple.com/gb/app/train-rex/id6476014470"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AppStore className="w-40 h-10 lg:h-20 cursor-pointer" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.arnav1224.gym_bro"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <PlayStore className="w-40 h-10 lg:h-20 cursor-pointer" />
                    </a>
                </div>
            </div>
        </div>
    );
};
