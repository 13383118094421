import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  query,
  where,
  limit,
} from "@firebase/firestore";
import { db, dbGym } from "../config/firebase";
import {
  IsCityValid,
  IfGymDocExists,
  addCollectionsToGyms,
} from "./GymFormHelpersExtended";
import { cityList, gymList } from "../components/Databases";

/**
 * Stores the City of the Gym
 * (Needs to check if the City already exists or not)
 * @param   :   City Name
 * @return  :   bool - true if success; false otherwise
 */
export const storeCity = async (cityName) => {
  try {

    // Check if the city is a valid city or not
    // TO BE DONE IN THE FUTURE
    // using Geocoding API
    if (!IsCityValid(cityName)) {
      return false;
    }

    // Database handling
    const cityRef = collection(db, cityList);
    const cityDocRef = doc(cityRef, cityName);
    const cityDoc = await getDoc(cityDocRef);

    // If the City is already listed, return
    if (cityDoc.exists()) {
      return true;
    }
    // Else add the City to the database
    await setDoc(cityDocRef, {
      city: cityName, // Name of the city
      NoOfGyms: 0, // Keeps track of the total gyms that ever partnered with us
      NoOfActiveGyms: 0, // Keeps track of the active gyms in the locality
    });

    return true;
  } catch (error) {
    // console.error("Error storing city : ", error);
    return false;
  }
};

/**
 * Stores the Gym Details
 * (Needs to check if the Gym already exists or not)
 * If yes, change the status to true.
 * @param   :   •   City
 *              •   Gym Name
 *              •   Locality
 *              •   Owner's Name
 *              •   Contact Number
 * @return  :   bool - true if success; false otherwise
 */
export const storeGym = async (info, user) => {
  try {

    const toUpdate = {
      gymID               : user.uid,
      name                : info.gymName,
      gymDisplayName      : info.gymDisplayName,
      codeName            : info.gymName.toLowerCase().replace(/\s+/g, ""), // This is done to find the same gym if exists
      address             : info.address,
      owner               : info.owner,
      status              : true,
      locality            : info.locality,
      phoneNumber         : info.contactNo,
      rating              : 0,
      NoOfReview          : 0,
      SumOfRatings        : 0,
      Portfolio           : "<url>",
      email               : info.email,
      openingTime         : info.openingTime,
      closingTime         : info.closingTime,
      pinCode             : info.pinCode,
      country             : info.country,
      dailyPrice          : info.dailyPrice,
      monthlyPrice        : info.monthlyPrice,
      quarterlyPrice      : info.quarterlyPrice,
      halfYearlyPrice     : info.halfYearlyPrice,
      yearlyPrice         : info.yearlyPrice,
      cityDisplayName     : info.cityDisplayName,
      city                : info.city,
      facilities          : info.facilities,
      equipmentList       : info.equipmentList,
      classes             : info.classes,
    };

    // Add Gym to Gym Database (trainrex website)
    await addUserToGymDoc(user, info);

    // Database management
    const gymRef = collection(db, cityList, info.city, gymList);
    const gymDocRef = doc(gymRef, info.gymName);

    // CodeName Reference to find the gym with same codeName
    const gymQuery = query(
      gymRef,
      where("codeName", "==", toUpdate.codeName),
      limit(1)
    );
    const gymDocs = await getDocs(gymQuery);

    // Check if the gym exists already
    // If yes, change the status to active
    if (!gymDocs.empty) {
      // Get the first document
      const gymDoc = gymDocs.docs[0];
      return await IfGymDocExists(info.city, gymDoc, gymDocRef, toUpdate);
    }

    // Add Details to the Website database, if gym doesn't exist
    await setDoc(gymDocRef, toUpdate);

    /*
     * Add further collections
     * Reviews  : Keeps Track of the reviews of the gym. Doc have field like    :   • Name of Reviewer
     *                                                                              • Review Date
     *                                                                              • Rating
     *                                                                              • Feedback
     *                                                                              • Suggestions for improvement
     *
     * Users    : Keeps Record of all the users. Users have fields like         :   • User Name
     *                                                                              • Member Since
     *                                                                              • User ID
     *                                                                              • Gym Membership ID
     *                                                                              • Address
     *                                                                              • Identification Number ? (like aadhar)
     *
     * Calendar : Keeps A tab of all the dates. These dates have fields like    :   • Timestamp
     *                                                                              • totalUsersAttendance  -   to keep a track of total users who came
     *                                                                              • Year
     *                                                                              • Month
     *                                                                              • Date
     *                                                  - Further Collections   :   • Attendance (individually see which users came)
     *                                                                              • Workout (this keeps track of what machines have users used on a specific day)
     *                                                                              • Classes (the classes the fitness centre is offereing on that specific date)
     *
     * Finances :   Keeps a tab of all the centre's finances, fields are like   :   • {Years} containing
     *                                                                              • TotalRevenue
     *                                                                              • TotalExpense
     *                                                  - Further Collections   :   • Revenue (Both has month like segregation and contain receits)
     *                                                                              • Expense
     *
     * Staff    : Keeps Record of all the staff members. Have fields like       :   • Staff Name
     *                                                                              • Staff ID
     *                                                                              • Staff Email
     *                                                                              • Salary
     *                                                                              • Position
     *                                                                              • Identification Number ? (like aadhar)
     */
    await addCollectionsToGyms(gymDocRef);

    // Update the number of gyms of the city
    const cityRef = collection(db, cityList);
    const cityDocRef = doc(cityRef, info.city);
    const cityDoc = await getDoc(cityDocRef);
    const cityData = cityDoc.data();

    await updateDoc(cityDocRef, {
      NoOfActiveGyms: cityData.NoOfActiveGyms + 1,
      NoOfGyms: cityData.NoOfGyms + 1,
    });

    return true;
  } catch (error) {
    // console.error("Error storing Gym : ", error);
    return false;
  }
};

const addUserToGymDoc = async (user, info) => {
  try {
    // console.log('Adding Gym to Firestore');
    const userRef = doc(dbGym, cityList, info.city, gymList, user.uid);
    await setDoc(userRef, info);
    return true;
  } catch (error) {
    // console.error("Error adding user to Firestore", error);
    return false;
  }
};

/**
 * Fetches all gym documents from the 'Gyms' collection in the database and returns :
 *
 * The function retrieves the following fields from each gym document:
 * - `id`: The unique identifier of the gym document.
 * - `name`: The name of the gym.
 * - `displayname`: The display name of the gym.
 * - `city`: The city where the gym is located.
 * - `locality`: The locality of the gym, converted to lowercase.
 *
 * Returns an array of objects containing the gym ID, name, display name, city, and locality.
 * If an error occurs during the fetch operation, it logs the error and returns an empty array.
 *
 * Further Collections:
 *
 * Reviews:
 * - Keeps track of the reviews of the gym.
 * - id: The unique identifier of the review document.
 * - name : Name of the Gym
 * - displayname : Display Name of the Gym
 * - city : City where the Gym is located
 * - locality : Locality of the Gym
 */
export const getAllGyms = async () => {
  try {
    const gymRef = collection(dbGym, "Gyms");
    const gymDocs = await getDocs(gymRef);
    // Extract only the gym ID and name
    const gymData = gymDocs.docs.map((doc) => ({
      id: doc.id, // Extract the document ID
      name: doc.data().gymName, // Extract the 'name' field from the document
      displayname: doc.data().gymDisplayName, // Extract the 'name' field from the document data
      city: doc.data().cityDisplayName,
      locality: doc.data().locality.toLowerCase(),
    }));
    return gymData;
  } catch (error) {
    // console.error("Error getting all gyms: ", error);
    return [];
  }
};

/**
 * Fetches the detials of a single gym baased on the gymID and reutrns data combined from two databases: 
 */
export const getSingleGym = async (gymID) => {
  try {
    // Reference to the specific gym document
    const gymRef = doc(dbGym, "Gyms", gymID);
    const gymDoc = await getDoc(gymRef);

    if (!gymDoc.exists()) {
      // console.error("No such document!");
      return {};
    }

    const docData = gymDoc.data();

    // Reference to another document based on city and gym name
    const gymMoreDataRef = doc(
      db,
      cityList,
      docData.city,
      gymList,
      docData.gymName
    );
    const gymMoreData = await getDoc(gymMoreDataRef);

    let moreData = {};
    if (!gymMoreData.exists()) {
      // console.error("No additional data found for the gym.");
    } else {
      moreData = gymMoreData.data();
    }

    // Merge data without duplicates
    const singleGymData = {
      id: gymDoc.id,
      ...docData,
      ...Object.fromEntries(
        Object.entries(moreData).filter(([key]) => !(key in docData))
      ),
    };

    return singleGymData;
  } catch (error) {
    // console.error("Error getting single gym: ", error);
    return {};
  }
};

/**
 * Returns an array of gym objects filtered by the city name.
 */
export const getGymByCity = async (city) => {
  try {
    const gymRef = collection(dbGym, "Gyms");
    const gymQuery = query(gymRef, where("cityDisplayName", "==", city));
    const gymDocs = await getDocs(gymQuery);

    // Extract only the gym ID and relevant fields
    const gymData = gymDocs.docs.map((doc) => ({
      id: doc.id, // Extract the document ID
      name: doc.data().gymName, // Extract the 'gymName' field from the document
      displayname: doc.data().gymDisplayName, // Extract the 'gymDisplayName' field from the document
      city: doc.data().cityDisplayName, // Extract the 'cityDisplayName' field from the document
      locality: doc.data().locality.toLowerCase(), // Extract and convert 'locality' to lowercase
    }));


    return gymData;
  } catch (error) {
    // console.error("Error getting gyms by city: ", error);
    return [];
  }
};
