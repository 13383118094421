import React, { useState } from 'react';
import GymMembershipOptions from './GymMembershipOptions';

/**
 * MembershipPlan
 * Displays the membership plan options for the gym, allowing users to toggle between Single, Couple, and Family plans.
 * 
 * @param {object} currGymData - The gym data containing price information.
 * @param {function} toggleSummaryModal - Function to open the summary modal with selected membership details.
 * @returns {JSX.Element} The membership plan component.
 */
const MembershipPlan = ({ currGymData, toggleSummaryModal }) => {
    const [selectedCategory, setSelectedCategory] = useState('single'); // Track selected price category

    // Define membership plans with labels and corresponding price types
    const plans = [
        { months : 0, label: "1 day", type: "dailyPrice", sellingPrice: "exclusiveDailyPrice" },
        { months : 1, label: "1 month", type: "monthlyPrice", sellingPrice: "exclusiveMonthlyPrice" },
        { months : 3, label: "3 months", type: "quarterlyPrice", sellingPrice: "exclusiveQuarterlyPrice" },
        { months : 6, label: "6 months", type: "halfYearlyPrice", sellingPrice: "exclusiveHalfYearlyPrice" },
        { months : 12, label: "12 months", type: "yearlyPrice", sellingPrice: "exclusiveYearlyPrice" },
    ];

    // Check if couple and family fields exist in currGymData
    const hasCouple = currGymData.prices?.couple && Object.keys(currGymData.prices.couple).length > 0;
    const hasFamily = currGymData.prices?.family && Object.keys(currGymData.prices.family).length > 0;

    return (
        <div className="flex flex-col border-2 lg:border-4 border-secondary rounded-2xl lg:h-[700px] justify-between">
            {/* Navbar for switching between Single, Couple, Family */}
            <div className="flex justify-between py-4 lg:py-0 lg:pt-8 pl-10 pr-6 lg:px-14 items-center text-tertiary">
                <div className="text-start flex-1 text-sm md:text-base lg:text-xl font-bold text-primary">Plan</div>
                <div className='flex-2'>
                    {/* Render the "Single" button */}
                    <button
                        className={`text-sm lg:text-xl px-4 py-2 font-bold border-r-2 border-tertiary ${selectedCategory === 'single' ? 'bg-primary' : 'bg-secondary text-primary'} rounded-l-full ${hasCouple && hasFamily ? '' : hasCouple ? '' : hasFamily ? '' : 'rounded-r-full'} transition-all duration-500`}
                        onClick={() => setSelectedCategory('single')}
                    >
                        Single
                    </button>

                    {/* Conditionally render the "Couple" button if data exists */}
                    {hasCouple && (
                        <button
                            className={`text-sm lg:text-xl px-4 py-2 font-bold ${selectedCategory === 'couple' ? 'bg-primary' : 'bg-secondary text-primary'} ${hasFamily ? '' : 'rounded-r-full'} transition-all duration-500`}
                            onClick={() => setSelectedCategory('couple')}
                        >
                            Couple
                        </button>
                    )}

                    {/* Conditionally render the "Family" button if data exists */}
                    {hasFamily && (
                        <button
                            className={`text-sm lg:text-xl px-4 py-2 font-bold border-l-2 border-tertiary ${selectedCategory === 'family' ? 'bg-primary' : 'bg-secondary text-primary'} rounded-r-full transition-all duration-500`}
                            onClick={() => setSelectedCategory('family')}
                        >
                            Family
                        </button>
                    )}
                </div>
            </div>

            <div className="flex mx-[12px] lg:mx-4 border-t-[1px] border-secondary lg:my-4"></div>

            {/* Render membership options based on selected category */}
            {plans.map(plan => (
                <GymMembershipOptions
                    key={plan.type}
                    label={plan.label}
                    months={plan.months}
                    membershipType={plan.type}
                    sellingPrice={plan.sellingPrice}
                    gymData={currGymData}
                    selectedCategory={selectedCategory} // Pass selected category
                    toggleSummaryModal={toggleSummaryModal} // Pass the toggleSummaryModal function
                />
            ))}
        </div>
    );
};

export default MembershipPlan;
