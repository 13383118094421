import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import { UserLoginModal } from "../../helperFunctions/ModalHelpers/UserCreds.js";
import { themeColors } from "../../theme/index.js";
import { NearYou } from "../../helperFunctions/MarketplaceHelpers/NearYou.js";
import GymPageSkeleton from "../../components/gymPageSkeleton.js";
import useAuthStatus from "../../hooks/clientAuth.js";
import { handlePayment } from "../../helperFunctions/RazorpayHelper.js";
import { fetchGymData } from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper.js";
import { sampleReviews } from "../../assets/Strings.js";
import GymDetailsComponent from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymDetailsComponent.js";
import { isRecentGymUser } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/GymReviewsHelper.js";
import { leadGeneration } from "../../helperFunctions/MarketplaceHelpers/gymDataHelpers/LeadGeneration.js";
import { removeSpacesAndConvertLowercase } from "../../helperFunctions/basicHelper.js";
import GymPortfolio from "../../helperFunctions/GymPageHelpers/GymPortfolio.js";
import GymInfoSection from "../../helperFunctions/GymPageHelpers/GymInfoSection.js";
import GymMembershipOptions from "../../helperFunctions/GymPageHelpers/GymMembershipOptions.js";
import MembershipPlan from "../../helperFunctions/GymPageHelpers/MembershipDuration.js";
import RatingAndReviews from "../../helperFunctions/GymPageHelpers/RatingsAndReviews.js";
import PaymentLoadingModal from "../../components/PaymentLoadingModal.js";
import PaymentConfirmationModal from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/PaymentConfirmationModal.js";
import { scroller } from "react-scroll";
import SuccessModal from '../../components/SuccessModal.js'

/**
 * Portfolio Page of a gym
 * The params should be taken from the previous page OR from the URL.
 * It fetches gym data, handles user authentication, payment processing, and displays gym details.
 * 
 * @param {Object} selectedCity - City selected from the marketplace.
 * @returns {JSX.Element} Renders the gym's portfolio page.
 */
const GymPage = ({ selectedCity }) => {
    const location    = useLocation();
    const { gymSlug } = useParams(); // Extract gymSlug from the URL.

    // Extract state passed from the previous page
    const { city: stateCity, gymName: stateGymName } = location.state || {};

    // Fallback to gymSlug if state data is not available.
    let city    = stateCity    || selectedCity;
    let gymName = stateGymName || gymSlug;

    const [currGymData        , setCurrGymData        ] = useState({});
    const [signInModal        , setSignInModal        ] = useState(false);
    const [myData             , setMyData             ] = useState({});
    const [loading            , setLoading            ] = useState(true);
    const [pendingPayment     , setPendingPayment     ] = useState(false);  // Tracks pending payment after sign-in.
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [successModal       , setSuccessModal       ] = useState(false);
    const {isUserPresent      , user                  } = useAuthStatus();

    // Reviews
    const [visibleReviews     , setVisibleReviews     ] = useState(3);
    const [showAddReviewModal , setShowReviewModal    ] = useState(false);

    // For Payment Summary Modal
    const [summaryModal       , setSummaryModal       ] = useState(false);
    const [membershipDetails  , setMembershipDetails  ] = useState(null);   // Stores membership details for the summary modal
    const [billInfo           , setBillInfo           ] = useState({});

    let isUserRecent = false;

    // Scroll to top on page load
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    /**
     * Fetches gym data and verifies if the user is a recent member of this gym.
     */
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await fetchGymData(city, gymName);
                if (data) {
                    setCurrGymData(data);

                    // Check if the user has been a recent member of this gym
                    isUserRecent = await isRecentGymUser(currGymData);
                }
            } catch (error) {
                // console.error("Error fetching gym data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [gymName]);

    /**
     * Handles lead generation after gym data is fetched.
     */
    useEffect(() => {
        const handleLeadGeneration = async () => {
            try {
                if (currGymData.city && currGymData.gymName) {
                    await leadGeneration(user, currGymData);
                }
            } catch (error) {
                // console.error("Error generating lead:", error);
            }
        };

        handleLeadGeneration();
    }, [currGymData, user]);

    /**
     * Automatically handles payment after user signs in if payment is pending.
     */
    useEffect(() => {
        if (pendingPayment && isUserPresent) {
            handlePayment({ ...myData, user }, setIsPaymentProcessing, setSuccessModal, setBillInfo);
            setPendingPayment(false); // Reset the pending payment flag after payment
        }
    }, [isUserPresent, myData, pendingPayment, user]);

    /**
     * Toggles the summary modal with membership details to review before making the payment.
     * 
     * @param {Number} months - The number of months for the selected membership.
     * @param {Number} amountToPay - The amount to be paid for the membership.
     * @param {Number} mrp - The MRP of the membership plan.
     */
    const toggleSummaryModal = (months, amountToPay, mrp) => {
        setMembershipDetails({ months, amountToPay, mrp });
        setSummaryModal(true); // Show the summary modal
    };

    /**
     * Handles membership button click and initiates the payment process.
     * 
     * @param {Number} months - The selected membership duration in months.
     * @param {Number} amount - The amount to be paid for the membership.
     */
    const handleMembershipButton = async (months, amount, mrp) => {
        setSummaryModal(false); // Close the summary modal

        // Prepare the gym data for payment
        const gymDataForPayment = {
            gymName         : currGymData.name,
            city            : currGymData.city,
            months          : months,
            amount          : amount * 100, // Convert to smallest currency unit (e.g., cents)
            image           : currGymData.logo,
            gymContactNo    : currGymData.gymContactNo || "",
            address         : currGymData.address,
            cityDisplayName : currGymData.cityDisplayName,
            gstin           : currGymData.gstin,
            gymDisplayName  : currGymData.gymDisplayName,
            gymEmail        : currGymData.email,
            mrp             : mrp,
        };

        if (!isUserPresent) {
            // If the user is not signed in, prompt sign-in modal
            setSignInModal(true);
            setMyData(gymDataForPayment); // Store payment data
            setPendingPayment(true); // Set pending payment flag for post-login processing
        } else {
            // Initiate payment process
            setIsPaymentProcessing(true);
            await handlePayment({ ...gymDataForPayment, user }, setIsPaymentProcessing, setSuccessModal, setBillInfo);
        }
    };

    /**
     * Shows more reviews in the review section.
     */
    const showMoreReviews = () => {
        setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 3);
    };

    /**
     * Scrolls to the specified section of the page.
     * 
     * @param {String} sectionId - The section ID to scroll to.
     */
    const scrollToSection = (sectionId) => {
        scroller.scrollTo(sectionId, {
            duration: 800,
            delay: 10,
            smooth: "easeInOutQuart",
            offset: -150,
        });
    };

    if (loading) {
        return <GymPageSkeleton />;
    }

    return (
        <>
            {/* SEO */}
            <Helmet>
                <title>{`Explore ${currGymData.gymDisplayName} in ${currGymData.cityDisplayName} | Train Rex`}</title>
                <meta
                    name="description"
                    content={`Discover ${currGymData.gymDisplayName}, located in ${currGymData.cityDisplayName}. Check out membership plans, facilities, reviews, and book your session now.`}
                />
                <meta
                    name="keywords"
                    content={`gyms in ${currGymData.cityDisplayName}, ${currGymData.gymDisplayName}, fitness classes, membership plans, personal trainers`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/${selectedCity}/gym/${gymSlug}`} />
            </Helmet>

            {currGymData ? (
                <div className="bg-tertiary flex flex-col px-8 lg:px-48 pt-48 space-y-8 w-full pb-16">
                    <GymPortfolio currGymData={currGymData} />

                    <UserLoginModal modalIsOpen={signInModal} setModalIsOpen={setSignInModal} myData={myData} showGuest={false} />

                    <GymInfoSection currGymData={currGymData} scrollToSection={scrollToSection} />

                    <div className="flex flex-col lg:flex-row gap-10">
                        <div className="lg:w-1/2 h-full">
                            <RatingAndReviews
                                visibleReviews      =   {visibleReviews}
                                setShowReviewModal  =   {setShowReviewModal}
                                isUserPresent       =   {isUserPresent}
                                currGymData         =   {currGymData}
                                user                =   {user}
                                isUserRecent        =   {isUserRecent}
                                showAddReviewModal  =   {showAddReviewModal}
                                sampleReviews       =   {sampleReviews}
                                showMoreReviews     =   {showMoreReviews}
                            />
                        </div>
                        <div className="lg:w-1/2 h-full">
                            <MembershipPlan currGymData={currGymData} toggleSummaryModal={toggleSummaryModal} />
                        </div>
                    </div>

                    <GymDetailsComponent gymData={currGymData} />

                    <PaymentLoadingModal isOpen={isPaymentProcessing} />

                    {summaryModal && (
                        <PaymentConfirmationModal
                            receiptInfo={{
                                gymName     : currGymData.gymDisplayName,
                                months      : membershipDetails?.months,
                                amountToPay : membershipDetails?.amountToPay,
                                mrp         : membershipDetails?.mrp - 1,
                            }}
                            onClose={() => setSummaryModal(false)}
                            handleMembershipButton={handleMembershipButton}
                        />
                    )}

                    <SuccessModal modalIsOpen={successModal} setModalIsOpen={setSuccessModal} billInfo={billInfo}/>
                </div>
            ) : (
                <div>No gym data available.</div>
            )}
        </>
    );
};

export default GymPage;
