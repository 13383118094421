import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Next } from "../../assets/WelcomeScreenImage";
import { Carousel, Placard } from "./ButtonsLibrary";
import { fetchAllBlogs, fetchAllGyms, getPlacardProps } from "./MarketplaceHelper";

export const NearYou = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [cityGymList, setCityGymList] = useState(null);
    const [blogList, setBlogList] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Handle screen resizing for responsiveness
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch gym or blog data based on the heading
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (props.heading.includes('BLOGS')) {
                const blogsList = await fetchAllBlogs();
                setBlogList(blogsList);
            } else {
                const cityGymList = await fetchAllGyms(props.selectedCity==='delhincr'?'ghaziabad':props.selectedCity);
                setCityGymList(cityGymList);
            }
            setLoading(false);
        };
        fetchData();
    }, [props.selectedCity, props.heading]);

    // Handle navigation logic
    const handleNavigation = () => {
        const propToPass = props.selectedCity;
        if (props.heading.includes('BLOGS')) {
            navigate('/blogs');
        } else if (props.heading.includes('FITNESS CENTRES')) {
            navigate(`/${propToPass}/gyms`, { state: { propToPass } });
        } else if (props.heading.includes('CLASSES')) {
            navigate(`/${propToPass}/classes`, { state: { propToPass } });
        }
    };

    // Scroll to the top when the gymName changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [props.gymName]);

    // Placard rendering logic inside NearYou
    const renderPlacards = (list, isBlog) => {
        return (list || []).slice(0, 3).map((item, index) => (
            <Placard
                key={index}
                {...getPlacardProps(item, props)}
            />
        ));
    };

    // If the component is loading data
    if (loading) return <div>Loading...</div>;

    return (
        <div className={`${props.bg} ${props.size} flex flex-col rounded-l-3xl lg:rounded-3xl lg:mt-4 mb-8 lg:mb-16 h-[400px] lg:h-1/4`}>
            <button 
                onClick={handleNavigation} 
                className="py-8 flex flex-row justify-between items-center px-8 transition-all duration-300 ease-in-out">
                <h1
                    className={`hidden lg:flex text-lg lg:text-4xl font-bold ${props.headingColor} 
                    transform transition-transform duration-300 ease-in-out hover:scale-105 text-left`}>
                    {isMobile ? props.headingMobile : props.heading}
                </h1>
                <div className="flex flex-row transform transition-transform duration-300 ease-in-out hover:scale-125">
                    <h3 className="text-lg lg:text-2xl text-secondary font-bold ">Explore All</h3>
                    <Next className="w-6 lg:w-12 h-4 lg:h-7 -mr-6 lg:-mr-0 mt-1 lg:mt-0" />
                </div>
            </button>

            <div className="w-full">
                {!props.heading.includes('BLOG') && (
                    <>
                        <div className="hidden lg:flex flex-row justify-between">
                            {renderPlacards(cityGymList, false)}
                        </div>
                        <div className="lg:hidden">
                            <Carousel slides={renderPlacards(cityGymList, false)} />
                        </div>
                    </>
                )}

                {props.heading.includes('BLOG') && (
                    <>
                        <div className="hidden lg:flex flex-row justify-between">
                            {renderPlacards(blogList, true)}
                        </div>
                        <div className="lg:hidden">
                            <Carousel slides={renderPlacards(blogList, true)} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
