import React, { useEffect, useState } from 'react';
import '../../App.css';
import { Placard } from '../../helperFunctions/MarketplaceHelpers/ButtonsLibrary';
import '../../index.css';
import { fetchAllBlogs } from '../../helperFunctions/MarketplaceHelpers/MarketplaceHelper';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import icon from '../../assets/Icon.jpeg';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../config/firebase';

function BlogList() {
    const location = useLocation();

    const [blogs, setBlogs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 8;
    const totalPages = blogs !== null && Math.ceil(blogs.length / itemsPerPage);

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    const currentItems = blogs !== null && blogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    useEffect(() => {
        setLoading(true);
        const fetchBlogs = async () => {
            const allBlogs = await fetchAllBlogs();
            setBlogs(allBlogs);
            setLoading(false);
        };
        fetchBlogs();
    }, []);

    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Marketplace BlogList', {
            page_path: location.pathname,
        });
    }, [location]);

    return (
        <>
            {/* SEO: Dynamic Helmet */}
            <Helmet>
                <title>Train Rex Blogs | Fitness Tips, Gym Guides, and More</title>
                <meta
                    name="description"
                    content="Explore the latest fitness blogs from Train Rex. Get expert fitness tips, gym guides, and stay updated with the fitness world."
                />
                <meta
                    name="keywords"
                    content="fitness blogs, gym guides, fitness tips, workout plans, Train Rex"
                />
                <link rel="canonical" href={`https://trainrexofficial.com/blogs`} />
            </Helmet>

            <div className="bg-tertiary flex flex-col px-0 lg:px-48 min-h-screen pt-20">
                {loading ? (
                    <div className="flex justify-center items-center h-full pt-32">
                        <p className="text-secondary text-lg lg:text-2xl font-bold">Loading...</p>
                    </div>
                ) : (
                    blogs !== null && (
                        <div className="flex flex-col py-8">
                            <h1 className="flex text-secondary text-lg lg:text-4xl font-bold pl-6 lg:pl-8 lg:pt-12 pb-6">
                                CHECKOUT THE TRAIN REX BLOGS
                            </h1>
                            {/* Blog List */}
                            <div className="flex flex-wrap">
                                {chunkArray(currentItems, 1).map((column, columnIndex) => (
                                    <div key={columnIndex} className="flex flex-col space-y-4 lg:space-y-8 mb-10 w-1/2 md:w-1/3 lg:w-1/4 pr-4"> {/* These width's denominator number is the number of cards in a row */}
                                        {column.map((card, index) => (
                                            <Placard
                                                key={index}
                                                hidden={true}
                                                bg="bg-secondary w-11/12 lg:w-[350px] h-[250px] md:h-[280px] lg:h-[420px]"
                                                textColor="text-tertiary"
                                                locality={`by ${card.locality}`}
                                                image={card.image ? card.image : icon}
                                                gymDisplayName={card.gymDisplayName}
                                                content={card.content}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>

                            {/* Pagination */}
                            <div className="flex justify-center mt-8">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleClick(index + 1)}
                                        className={`mx-1 px-3 py-1 rounded ${
                                            index + 1 === currentPage
                                                ? 'bg-secondary text-tertiary'
                                                : 'bg-tertiary text-secondary border-2 border-secondary'
                                        }`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </div>
        </>
    );
}

export default BlogList;
