export const coupons = [
    {
        code            : 'SAVE10',
        description     : 'Flat 10% off up to Rs 100',
        type            : 'percentage',
        value           : 10,          // 10% off
        maxDiscount     : 100,    // Max discount Rs 100
        minDuration     : 1,
    },
    {
        code            : 'SAVE200',
        description     : 'Flat Rs 200 off',
        type            : 'fixed',
        value           : 200,         // Rs 200 off directly
        minDuration     : 3,
        maxDuration     : 3,
    },
    {
        code            : 'SAVE20',
        description     : 'Flat Rs 20 off',
        type            : 'fixed',
        value           : 20,         // Rs 20 off directly
        minDuration     : 0,
        maxDuration     : 0,
    },
    {
        code            : 'SAVE500',
        description     : 'Flat Rs 500 off',
        type            : 'fixed',
        value           : 500,         // Rs 500 off directly
        minDuration     : 6,
    },
    {
        code            : '3MUSKETEERS',
        description     : 'Flat 3% off',
        type            : 'percentage',
        value           : 3,          // 10% off
        minDuration     : 6,
        maxDiscount     : 5000,
    },
];
