
import { collection, getDocs, limit, orderBy, query, where } from "@firebase/firestore";
import { auth, db } from "../../../config/firebase";

export const getDaysAgo = (isoString) => {
    // Create Date objects for the current date and the input timestamp
    const currentDate = new Date();
    const inputDate = new Date(isoString);

    // Remove the time portion from both dates by setting the hours to 00:00:00
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    // Calculate the difference in time (milliseconds) between the two dates
    const timeDiff = currentDate - inputDate;

    // Convert the time difference from milliseconds to days
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    // Return human-readable output based on the number of days ago
    if (daysDiff === 0) {
        return 'Today';
    } else if (daysDiff === 1) {
        return 'Yesterday';
    } else if (daysDiff > 1) {
        return `${daysDiff} days ago`;
    } else {
        return `In the future`;
    }
}

export const isRecentGymUser = async (props) => {
    try {
        const user = auth.currentUser; // Get logged-in user
        if (!user) return false; // Return false if no user is logged in

        // Fetch user's document based on email
        const userQuery = query(
            collection(db, 'user'),
            where('email', '==', user.email)
        );
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) return false; // No user document found

        // Assuming there's only one user doc per email
        const userDoc = userSnapshot.docs[0];

        // Fetch the three most recent gym receipts
        const gymMembershipReceiptRef = collection(userDoc.ref, 'gymMembershipReceipt');
        const receiptsQuery = query(
            gymMembershipReceiptRef,
            orderBy('timestamp', 'desc'), // Sort by timestamp
            limit(3) // Get the latest 3 documents
        );
        const receiptsSnapshot = await getDocs(receiptsQuery);

        // Check if any of the receipts' gymName matches the given gymName
        const isRecentUser = receiptsSnapshot.docs.some((receipt) => {
            const receiptData = receipt.data();
            return receiptData.gymName === props.currGymData.gymName; // Compare gymName
        });

        return isRecentUser;
    } catch (error) {
        // console.error("Error checking recent gym user:", error);
        return false;
    }
};