import React, { useState, useRef, useEffect } from 'react';
import { themeColors } from '../../theme';
import { LocationIcon, SearchIcon } from '../../assets/WelcomeScreenImage';
import SearchDropDown from '../Navbar_helpers/SearchDropDown';
import { fetchCities } from './MarketplaceHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";

const SearchBar = (props) => {
    // State to manage dropdown visibility
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // State to store the selected location, default is Delhi or the provided prop
    const [selectedLocation, setSelectedLocation] = useState(props.location || 'NCR');
    // State to manage the search query entered by the user
    const [searchQuery, setSearchQuery] = useState('');
    const [searchDropDown, setSearchDropDown] = useState(false); // For search suggestions dropdown
    const [allCities, setAllCities] = useState([]);
    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false); // For Gym/Spa/Yoga dropdown
    const [selectedCategory, setSelectedCategory] = useState('Gym'); // Default category
    
    const searchBarRef = useRef(null);
    const navigate = useNavigate();
    const link = useLocation();

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleCategoryDropdown = () => {
        setCategoryDropdownOpen(!categoryDropdownOpen);
    };

    const handleLocationChange = (location) => {
        
        setSelectedLocation(location);
        
        props.onLocationChange(location);

        if (link.pathname.includes('gyms')) {
            // If on the Listing page, update the URL to /{city}/gyms
            navigate(`/${location}/gyms`);
        } else {
            // If on the Marketplace page, update the URL to /{city}
            navigate(`/${location}`);
        }
        setDropdownOpen(false);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCategoryDropdownOpen(false); // Close dropdown on selection
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
                setSearchDropDown(false);
                setCategoryDropdownOpen(false); // Close category dropdown on click outside
            }
        };

        const fetchCity = async () => {
            const cities = await fetchCities();
            setAllCities(cities);
        };

        fetchCity();
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSearch = (searchQuery) => {
        setSearchDropDown(false);
        navigate(`/${selectedLocation}/result?search=${searchQuery}&category=${selectedCategory}`, {
            state: { city: selectedLocation, category: selectedCategory },
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(searchQuery);
        }
    };

    return (
        <div
            ref={searchBarRef}
            className={`relative flex flex-row items-center space-x-4 md:space-x-2 w-full ${props.classname} px-8 md:px-12`}
        >
            {/* Location selection dropdown */}
            <div
                onClick={toggleDropdown}
                className="text-xs md:text-sm lg:text-base flex flex-row justify-center items-center bg-secondary rounded-full text-tertiary w-full lg:w-48 px-2 lg:px-3 py-2 cursor-pointer"
            >
                <LocationIcon className="w-6 lg:w-8 h-4 lg:h-5 lg:mt-0" color={themeColors.lightAccent} />
                <span className="inline mr-1 font-semibold">
                    {selectedLocation.charAt(0).toUpperCase() + selectedLocation.slice(1)}
                </span>
            </div>

            {/* Dropdown menu for cities */}
            {dropdownOpen && (
                <div className="absolute top-12 left-5 bg-white border border-secondary rounded-md shadow-lg w-1/3">
                    <ul>
                        {allCities.map((location) => (
                            <li
                                key={location}
                                className="text-sm md:text-base px-2 md:px-4 py-1 md:py-2 hover:bg-gray-200 cursor-pointer"
                                onClick={() => handleLocationChange(location)}
                            >
                                {location.charAt(0).toUpperCase() + location.slice(1)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Search bar with input field */}
            <div className="flex items-center w-full py-1 px-2 order-last rounded-full bg-tertiary border-2 rounded-2xl border-secondary relative">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onClick={() => setSearchDropDown(true)}
                    onKeyDown={handleKeyDown}
                    placeholder="  Search"
                    autoFocus={false}
                    className="flex-grow bg-tertiary w-full placeholder-color custom-input text-sm md:text-base"
                />

                {/* Category dropdown */}
                {/* <div className="relative">
                    <div className="px-5 flex items-center cursor-pointer" onClick={toggleCategoryDropdown}>
                        <div className="text-sm bg-primary text-white px-2 py-[1px] rounded-md flex items-center justify-between">
                            <div>{selectedCategory}</div>
                            <IoMdArrowDropdown size={16} />
                        </div>
                    </div>
                    {categoryDropdownOpen && (
                        <div className="absolute bg-white border border-secondary rounded-md shadow-lg mt-1 w-20 text-sm right-2">
                            <ul>
                                {['Gym', 'Spa', 'Yoga'].map((category) => (
                                    <li
                                        key={category}
                                        className="px-2 py-2 hover:bg-secondary/20 cursor-pointer rounded-md"
                                        onClick={() => handleCategoryChange(category)}
                                    >
                                        {category}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div> */}

                {/* Search button */}
                <button type="submit" className="flex text-primary">
                    <SearchIcon className="w-6 h-4 mt-1" onClick={() => handleSearch(searchQuery)} />
                </button>

                {/* Search dropdown for query suggestions */}
                {searchDropDown && (
                    <SearchDropDown
                        setSearchQuery={setSearchQuery}
                        searchQuery={searchQuery}
                        setSearchDropDown={setSearchDropDown}
                        cityLocation={selectedLocation}
                    />
                )}
            </div>
        </div>
    );
};

export default SearchBar;
