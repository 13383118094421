import React, { useEffect, useRef } from 'react';
import JSConfetti from 'js-confetti';
import { generatePdf } from '../helperFunctions/MarketplaceHelpers/PaymentHelpers/BillGeneration';
import AeMoorkh from '../assets/AeMoorkhSuccess.png';

/**
 * SuccessModal Component
 * Displays a modal after a successful payment, including confetti animation and options to print the receipt.
 * 
 * @param {boolean} modalIsOpen - Boolean flag to control the visibility of the modal.
 * @param {Function} setModalIsOpen - Function to toggle the modal visibility.
 * @param {Object} billInfo - Contains billing information to be used for generating the PDF.
 * 
 * @returns {JSX.Element} - Renders the success modal.
 */
const SuccessModal = ({ modalIsOpen, setModalIsOpen, billInfo }) => {
    // console.log('billInfo : ', billInfo);
    const confettiRef = useRef(null); // Reference for confetti instance

    /**
     * useEffect Hook
     * Triggers the confetti animation every time the modal is opened by creating a new instance of JSConfetti.
     * The confetti animation is displayed only if the modal is open and reset on every modal open.
     */
    useEffect(() => {
        if (modalIsOpen) {
            // Reset confettiRef to trigger confetti animation on every modal open
            const jsConfetti = new JSConfetti();
            confettiRef.current = jsConfetti;

            // Add confetti with fun emojis and parameters
            jsConfetti.addConfetti({
                emojis: ['🎉', '💪', '🔥', '🏋️‍♂️'],
                confettiRadius: 6,
                confettiNumber: 100,
            });
        }
    }, [modalIsOpen]);

    /**
     * handleClose Function
     * Closes the modal when the close button is clicked or when the background is clicked.
     */
    const handleClose = () => {
        setModalIsOpen(false); // Close the modal by setting the flag to false
    };

    // Do not render the modal if it's not open
    if (!modalIsOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30" onClick={handleClose}>
            {/* Modal content */}
            <div className="bg-secondary p-8 rounded-lg shadow-xl flex w-full max-w-6xl h-auto" onClick={(e) => e.stopPropagation()}>
                {/* Image on the left */}
                <div className="w-1/2 flex items-center justify-center flex-col">
                    <img src={AeMoorkh} alt="Success" className="rounded-lg w-full h-auto" />
                </div>
    
                {/* Text content on the right */}
                <div className="w-1/2 flex flex-col justify-between pl-8">
                    {/* Title aligned with image top */}
                    <div className="text-4xl font-bold text-center mb-4">
                        🎉 Next Step: Hit the Gym 🎉
                    </div>
    
                    {/* Quote */}
                    <div className="text-lg font-semibold text-center mb-4">
                        "Success starts with the first step. Now, go and crush it!" 💪
                    </div>
    
                    {/* Billing Information */}
                    <div className="text-lg text-center">
                        {/* MRP with strike-through */}
                        <div className="mb-2">
                            MRP: <span className="line-through">Rs. {billInfo.mrp}</span>
                        </div>
    
                        {/* Amount in bold */}
                        <div className="font-bold text-2xl mb-2">
                            Amount Paid: Rs. {billInfo.amount / 100}
                        </div>
    
                        {/* Total Savings */}
                        <div className="text-primary text-3xl font-semibold mb-2">
                            Total Savings: Rs. {billInfo.mrp - billInfo.amount / 100}
                        </div>
    
                        {/* Membership Expiration Date */}
                        <div className="mt-2">
                            Gym Membership expires on: <span className="font-bold">{billInfo.endDate}</span>
                        </div>
                    </div>
    
                    {/* Buttons aligned with image bottom */}
                    <div className="flex justify-center space-x-4 mt-4">
                        <button
                            onClick={() => generatePdf(billInfo, billInfo.response)}
                            className="px-6 py-2 bg-primary text-white rounded-lg font-semibold w-32 hover:bg-tertiary hover:text-primary">
                            Print
                        </button>
                        <button
                            onClick={handleClose}
                            className="px-6 py-2 bg-primary text-white rounded-lg font-semibold w-32 hover:bg-tertiary hover:text-primary">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
        
};

export default SuccessModal;
