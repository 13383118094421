import { addDoc, collection, doc, getDoc, increment, setDoc } from "@firebase/firestore";
import { db } from "../config/firebase";
import { getDate } from "./basicHelper";

/**
 * Stores data in 'user' database
 * @param {Object} myData - User data including city, gymName, months, userID
 * @param {Object} response - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean} - True if save successful, false otherwise
 */
export const storeUserData = async (myData, response) => {
    try {
        const myUserRef = doc(collection(db, 'user'), myData.user.uid);
        const userDoc = await getDoc(myUserRef);

        // If no such user with userID exists
        if (!userDoc.exists()) {
            // console.log('No User Found');
            return {status : false, endDate : ''};
        }

        // Add receipt to user -> {userID} -> gymMembershipReceipts collection
        const gymMembershipRef = collection(db, 'user', myData.user.uid, 'gymMembershipReceipts');
        await addDoc(gymMembershipRef, {
            timestamp   : new Date().toISOString(),
            paymentID   : response.razorpay_payment_id || "Self",
            orderType   : myData.orderType || 'Gym Membership',
            months      : myData.months || null,
            gymName     : myData.gymName,
            city        : myData.city,
            amount      : myData.amount,
            className   : myData.className || null,
            classSchedule: myData.classSchedule || null
        });

        // Update user doc
        const userData = userDoc.data();
        // storing the startDate and endDate
        const startDate = userData.membershipTill && parseDate(userData.membershipTill) > new Date() 
            ? userData.membershipTill 
            : getDate();
        const endDate   =   addMonthsToDate(startDate, myData.months)

        await setDoc(myUserRef, {
            membershipTill  :   endDate,
            gymName         :   myData.gymName,
            gymCity         :   myData.city,
            trexCredits     :   increment(myData.amount * 0.0001),    // Automatically increments from 0, if undefined.
            // Add a gymMembershipID if necessary
        }, { merge: true });

        return {
            status : true, 
            endDate : endDate, 
            startDate : startDate,
        };

    } catch (e) {
        // console.log('Error in Store User Data : ', e);
        return {status : false, endDate : ''};
    }
};

// Helper function to convert date strings of format 'DD-MM-YYYY' to a Date object
const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month}-${day}`);
};


/**
 * Adds months to a given date
 * @param {string} dateStr - The date string in YYYY-MM-DD or DD-MM-YYYY format (or undefined)
 * @param {number} months - The number of months to add
 * @returns {string} - The new date string in DD-MM-YYYY format
 */
export const addMonthsToDate = (dateStr, months) => {
    let date;

    // Check if dateStr is undefined or not a valid date
    if (!dateStr || isNaN(Date.parse(convertToISOFormat(dateStr)))) {
        date = new Date(); // Use today's date if dateStr is undefined or invalid
    } else {
        date = new Date(convertToISOFormat(dateStr));
    }

    // Calculate the new month and year
    const newMonth = date.getMonth() + months;
    date.setMonth(newMonth);

    // Handle month overflow
    if (date.getMonth() !== (newMonth % 12)) {
        date.setDate(0);
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${day}-${month}-${year}`;
};

/**
 * Converts a date string in DD-MM-YYYY format to YYYY-MM-DD format
 * @param {string} dateStr - The date string in DD-MM-YYYY format
 * @returns {string} - The date string in YYYY-MM-DD format
 */
const convertToISOFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
};

// function to get users data from uid
export const getUserData = async (userID) => {
    if (!userID) {
      console.log('User ID is null or undefined.');
      return null;
    }
    try {
      const userRef = doc(collection(db, 'user'), userID);
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        return null;
      }
      const userData = userDoc.data();
      return {
        ...userData,
        trexCredits: userData.trexCredits !== undefined ? userData.trexCredits : 0,
      };
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };
  