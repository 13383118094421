import React, { useEffect, useState } from "react";
import "../../App.css";
import { Helmet } from "react-helmet-async"; // Import Helmet for SEO
import { ListCard } from "../../helperFunctions/MarketplaceHelpers/ButtonsLibrary";
import "../../index.css";
import {
fetchAllGyms,
} from "../../helperFunctions/MarketplaceHelpers/MarketplaceHelper";
import { useLocation, useParams } from "react-router-dom";
import icon from "../../assets/Icon.jpeg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
import Map from "../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Map";

function Listing() {
const location = useLocation();
const { city } = useParams(); // extract the city from the URL

useEffect(() => {
    // Log page view event when route changes
    logEvent(analytics, "Marketplace Listing", {
    page_path: location.pathname,
    });
}, [location]);

const [currentPage, setCurrentPage] = useState(1);
const [cityGymList, setCityGymList] = useState(null);
const [loading, setLoading] = useState(true); // Add loading state

const itemsPerPage = 10;
const totalPages = cityGymList ? Math.ceil(cityGymList.length / itemsPerPage) : 0;

const handleClick = (page) => {
    setCurrentPage(page);
};

const currentItems = cityGymList ? cityGymList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : [];

useEffect(() => {
    setLoading(true); // Start loading

    const cityGyms = async () => {
    const cityGymList = await fetchAllGyms(city); // Use selectedCity prop
    setCityGymList(cityGymList);
    setLoading(false); // Stop loading after gyms are fetched
    };

    cityGyms();
}, [city]); // Refetch when selectedCity changes

return (
    <>
    {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
    <Helmet>
        <title>Find Gyms in {city} | Top Fitness Centers | Train Rex</title>
            <meta
                name="description"
                content={`Explore top-rated gyms and fitness centers in ${city}. Discover membership deals, free trials, and expert trainers. Start your fitness journey today with Train Rex.`}
            />
            <meta
                name="keywords"
                content={`gyms in ${city}, fitness centers, free trials, membership deals, personal trainers`}
            />
        <link rel="canonical" href={`https://trainrexofficial.com/${city}/gyms`} />
    </Helmet>

    <div className="bg-tertiary flex flex-col px-4 lg:px-48 min-h-screen pt-28 lg:pt-32">
        {loading ? (
        <div className="flex justify-center items-center h-full pt-32">
            <p className="text-secondary text-lg lg:text-2xl font-bold">
                Loading...
            </p>
        </div>
        ) : (
            cityGymList && (
                <div className="flex flex-col py-8">

                    <div className="w-[99.1vw] ml-0 lg:-ml-48 mb-10">
                        <Map selectedCity={city} currentPage="listing" />
                    </div>


                    <h1 className="flex text-secondary text-lg lg:text-4xl font-bold text-left pl-2">
                        SHOWING GYMS IN YOUR AREA
                    </h1>
                    <div className="">
                        <div className="flex flex-wrap">
                        {currentItems.map((card, index) => (
                            <ListCard
                                key={index}
                                image={card.image || [icon, icon, icon]}
                                name={card.gymName}
                                city={card.city}
                                gymDisplayName={card.gymDisplayName}
                                rating={card.rating}
                                NoOfReview={card.NoOfReview}
                                locality={card.locality}
                                buttonText={"EXPLORE"}
                                extraText={"Free Trials | Membership deals Available"}
                                stars={true}
                                price={card.price}
                            />
                        ))}
                        </div>
                        <div className="flex justify-center mt-8">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                            key={index}
                            onClick={() => handleClick(index + 1)}
                            className={`mx-1 px-3 py-1 rounded ${
                                index + 1 === currentPage
                                ? "bg-secondary text-tertiary"
                                : "bg-tertiary text-secondary border-2 border-secondary"
                            }`}
                            >
                            {index + 1}
                            </button>
                        ))}
                        </div>
                    </div>
                </div>
            )
        )}
    </div>
    </>
);
}

export default Listing;
