import { collection, getDocs } from '@firebase/firestore';
import { db } from "../../config/firebase";

// Function to get all classes from all gyms in a specific city (e.g., 'delhi')
export const getAllClasses = async () => {
    try {
        const classesList = [];
        
        // Fetch all gym documents from the 'Gyms' collection inside 'Cities/delhi'
        const gymsSnapshot = await getDocs(collection(db, 'Cities', 'delhi', 'Gyms'));

        for (const gymDoc of gymsSnapshot.docs) {
            const gymData = gymDoc.data();

            // Fetch classes subcollection for each gym
            const classesSnapshot = await getDocs(collection(db, 'Cities', 'delhi', 'Gyms', gymDoc.id, 'Classes'));

            // Loop through classes and append gym name and location to each class
            classesSnapshot.forEach(classDoc => {
                const classData = classDoc.data();
                classesList.push({
                    classId            : classDoc.id,
                    className          : classData.className,
                    classSchedule      : classData.time,
                    priceForMembers    : classData.priceForMembers,
                    priceForNonMembers : classData.priceForNonMembers,
                    trainer            : classData.trainer,
                    isActive           : classData.isActive,
                    availableSlots     : classData.availableSlots,
                    totalSlots         : classData.totalSlots,
                    gymName            : gymData.gymDisplayName, // Gym name from parent document
                    gymLocation        : gymData.cityDisplayName, // Gym location from parent document
                });
            });
        }
        return classesList;
    } catch (error) {
        throw new Error('Could not retrieve classes');
    }
};
