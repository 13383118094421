/**
 * HandlingReviews.js
 * 
 * Contains the helper functions to fetch / add reviews into the database
 */

import { collection, doc, getDoc, getDocs } from "@firebase/firestore"
import { db } from "../../../config/firebase"
import { cityList, gymList, reviewList } from "../../../components/Databases"

/**
 * Fetches the Reviews of the gym
 * @param {Object} basicGymData - Contains gymName and city
 * @returns {Object} - Contains the reviews of the gym
 */
export const fetchGymReviews = async (basicGymData) => {
    let reviews = [];
    try {
        // Reference to the Reviews collection for the specified gym
        const reviewRef = collection(db, cityList, basicGymData.city, gymList, basicGymData.name, reviewList);

        // Fetch reviews
        const reviewsSnapshot = await getDocs(reviewRef);
        reviewsSnapshot.forEach((doc) => {
            // Filter out the collection started review
            if (doc.id == 'testReview')
                return;

            reviews.push({
                id: doc.id,
                ...doc.data()
            });
        });

    } catch (error) {
        // console.error('Error in Fetching Reviews:', error);
    }

    return reviews;
};