import React, { useEffect, useState } from 'react';
import { ReactComponent as TR_MainLogo } from '../../../assets/TR_Written.svg'; // Logo
import CouponModal from './CouponModal';
import SelectedCoupon from './SelectedCoupon';  // Import the SelectedCoupon component
import { checkDiscountCodeValidity } from './DiscountHelpers';
import { getUserData } from '../../userDatabaseHelper';
import useAuthStatus from '../../../hooks/clientAuth';

/**
 * PaymentConfirmationModal
 * Displays the payment confirmation details, such as gym name, membership duration, and pricing.
 * Allows users to select and apply a coupon, and shows a breakdown of the final total after applying discounts.
 * 
 * @param {object} receiptInfo - The receipt information including gym name, amount to pay, and MRP.
 * @param {function} onClose - Function to close the modal.
 * @param {function} handleMembershipButton - Function to handle payment after confirmation.
 * @returns {JSX.Element} The payment confirmation modal.
 */
const PaymentConfirmationModal = ({ receiptInfo, onClose, handleMembershipButton }) => {
    // console.log('Receipt : ', receiptInfo);
    const { isUserPresent, user } = useAuthStatus();
    const [selectedCoupon, setSelectedCoupon] = useState(null);  // Stores the currently selected coupon
    const [totalAmount, setTotalAmount] = useState(receiptInfo.amountToPay);  // Stores the total amount after coupon
    const [showCouponModal, setShowCouponModal] = useState(false);  // Manages the visibility of the coupon modal
    const [discountCode, setDiscountCode] = useState('');  // Tracks the input discount code
    // trex coins
    const [trexCoins, setTrexCoins] = useState(0);  // Stores the user's Trex coins
    const [trexToBeUsed, setTrexToBeUsed] = useState(false);  // Stores the Trex coins to be used
    const [trexError, setTrexError] = useState('');  // Stores the error message for Trex coins
    const [totalMoneySaved, setTotalMoneySaved] = useState(receiptInfo.mrp - receiptInfo.amountToPay);  // Stores the total money saved
    // alert(`mrp : ${receiptInfo.mrp} , amount : ${totalAmount}, saved : ${totalMoneySaved}`);


    useEffect(() => {
        const fetchTrexCoins = async () => {
            const userData = await getUserData(user?.uid);
            setTrexCoins(userData?.trexCredits ? userData?.trexCredits : 0);
        }
        fetchTrexCoins()

        setTotalMoneySaved(receiptInfo.mrp - totalAmount);
    }, [user])

    // let isMac = navigator.userAgentData.platform === 'macOS';
    let isMac = false;

    // Calculate the total saved amount based on MRP and the discounted amount
    // const totalSaved = receiptInfo.mrp - totalAmount;
    const mrp = parseInt(receiptInfo.mrp, 10);  // 10 is the radix for decimal numbers

    /**
     * handleCouponSelection
     * Applies the selected coupon and adjusts the total amount accordingly.
     * 
     * @param {object} coupon - The coupon object containing discount details.
     */
    const handleCouponSelection = (coupon) => {
        let discount = coupon.type === 'percentage'
            ? Math.min((coupon.value / 100) * receiptInfo.amountToPay, coupon.maxDiscount)
            : coupon.value;
        setTotalAmount(receiptInfo.amountToPay - discount);
        setTotalMoneySaved(totalMoneySaved + discount);
        setSelectedCoupon(coupon);
        setShowCouponModal(false);  // Close the coupon modal after selection
    };

    if (navigator.userAgentData) {
        const platform = navigator.userAgentData.platform;
      } else {
        // Fallback for older browsers
        const platform = navigator.platform;
      }

    /**
     * removeCoupon
     * Removes the applied coupon and resets the total amount to the original amount to pay.
     */
    const removeCoupon = () => {
        setTotalAmount(receiptInfo.amountToPay);
        setSelectedCoupon(null);
        setTotalMoneySaved(receiptInfo.mrp - receiptInfo.amountToPay);
    };

    // Function to Toggle if Trex coins are to be used
    const toggleTrexToBeUsed = () => {
        setTrexToBeUsed(!trexToBeUsed);
    };

    // Logic to update the total amount and total money saved based on Trex coins
    useEffect(() => {
        if (trexToBeUsed) {
            setTotalMoneySaved(totalMoneySaved + trexCoins);
            setTotalAmount(totalAmount - trexCoins);
        } else {
            setTotalMoneySaved(totalMoneySaved - trexCoins);
            setTotalAmount(totalAmount + trexCoins);
        }
    }, [trexToBeUsed])
   
    return (
        <>
            {/* Main Payment Modal */}
            <div className="fixed inset-0 -top-8 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className={`relative bg-secondary md:bg-tertiary pt-6 md:p-6 lg:p-10 sm:p-12 rounded-3xl shadow-xl w-11/12 ${isMac ? 'w-[84vw] scale-95' : 'w-fit lg:w-5/6 md:w-full'}  mx-auto`}>

                    {/* Close button (X) */}
                    <button
                        onClick={onClose}
                        className="z-50 absolute top-0 right-4 text-primary text-4xl hover:text-secondary transition"
                    >
                        &times;
                    </button>

                    {/* Modal Content */}
                    <div className="flex flex-col lg:flex-row justify-between">


                        {/* Left Side: Coupon Display Section */}
                        <div className="w-full lg:w-2/3  bg-secondary p-6 rounded-b-none rounded-t-3xl lg:rounded-b-3xl mr-4 relative">
                            {/* Logo in the top-left */}
                            <div className="absolute top-7 left-7">
                                <TR_MainLogo className="w-60 md:w-80 lg:w-96 text-primary fill-current" />
                            </div>

                            {/* Savings message in the top-right in two lines */}
                            <div className="absolute top-6 md:top-4 right-4 text-right italic">
                                <p className="md:text-2xl lg:text-5xl font-bold text-tertiary">Congratulations</p>
                                <p className="md:text-2xl lg:text-5xl font-bold text-tertiary">You saved <span className='text-primary'>₹{totalMoneySaved.toFixed(2)}!</span></p>
                            </div>

                            {/* If a coupon is selected, display the SelectedCoupon component */}
                            <div className="flex items-center justify-center h-full w-full">
                                {selectedCoupon ? (
                                    <div className="h-32 md:h-40 lg:h-72 mt-14 md:mt-20 lg:mt-32 w-full">  {/* Increase height to 64 and add shadow */}
                                        <SelectedCoupon selected={true} coupon={selectedCoupon} />  {/* Pass the selected coupon to the SelectedCoupon component */}
                                    </div>
                                ) : (
                                    <div className='h-32 md:h-40 lg:h-72 mt-14 md:mt-20 lg:mt-32 w-full'>
                                        <SelectedCoupon selected={false} coupon={{}} />
                                    </div>
                                )}
                            </div>
                        </div>


                        {/* Right Side */}
                        <div className="w-full lg:w-1/3 bg-secondary p-6 rounded-t-none rounded-b-3xl lg:rounded-t-3xl -mt-5 md:-mt-4 lg:mt-0 flex flex-col justify-between">
                            <div>
                                <h3 className="text-xl md:text-3xl lg:text-xl font-bold md:font-bold lg:font-semibold mb-4 text-tertiary">Order Summary</h3>
                                <div className="bg-tertiary py-3 px-4 rounded-lg shadow-lg font-semibold text-primary" style={{ boxShadow: "0 0 3px 1px #465130" }}>
                                    <p className="text-xl mb-2 font-bold">{receiptInfo.gymName}</p>
                                    <p className="text-xl mb-2 font-bold">{receiptInfo.amountToPay > 1000 ? `${receiptInfo.months} ${receiptInfo.months > 1 ? 'Months' : 'Month'}` : `1 Day`}</p>
                                    <div className="flex justify-between mb-2 sm:mb-4">
                                        <span>Base Price</span>
                                        <span>₹{mrp.toFixed(2)}</span>
                                    </div>
                                    {/* Display the Trex coins only when user checks the box */}
                                    {
                                        trexToBeUsed && (
                                            <div className="flex justify-between mb-1 sm:mb-3">
                                                <span>Trex Coins</span>
                                                <span>- ₹{trexCoins} </span>
                                            </div>
                                        )
                                    }
                                    <div className="flex justify-between mb-1 sm:mb-3">
                                        <span>Discount</span>
                                        <span>- ₹{(mrp - totalAmount).toFixed(2)}</span>
                                    </div>
                                    <div className='bg-gray-700 w-full h-[1px]'></div>
                                    {/* <div className='text-logoGray font-normal md:font-thin lg:font-normal scale-x-100 md:scale-x-125 lg:scale-x-100 -mt-4 text-xs md:text-lg lg:text-xs'>----------------------------------------------------------------</div> */}
                                    <div className="flex justify-between text-lg sm:text-xl font-semibold">
                                        <span>Total</span>
                                        <span>₹{totalAmount.toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>

                             {/* User can use Trex coins to pay for the membership */}
                            <div className='py-2 flex gap-2 flex-col'>
                                <div className='text-sm flex justify-start text-primary font-bold'> Available Trex Coins : {trexCoins} </div>
                                <div className='flex text-sm font-bold text-primary gap-2 items-center'>
                                    <label> Use TrexCoins</label>
                                    <input onClick={toggleTrexToBeUsed} value={trexToBeUsed} type='checkbox' className="w-4 h-4 accent-primary rounded-md cursor-pointer" />
                                </div>
                            </div>

                            <div className='flex flex-row lg:flex-col justify-between md:justify-around lg:justify-center my-4 h-fit gap-0 lg:gap-5 '>

                                {/* Show the option to select the coupon when no coupon is selected */}
                                {!selectedCoupon && <button
                                    onClick={() => setShowCouponModal(true)}
                                    className="w-5/12 md:w-2/5 lg:w-full text-sm md:text-xl bg-tertiary text-secondary font-semibold py-2 px-4 md:px-6 rounded-full hover:font-bold hover:scale-105 duration-500 transition-all"
                                    style={{
                                        boxShadow: '0 0 3px 1px #465130', // Default shadow with color #465130
                                    }}
                                    onMouseEnter={(e) => (e.target.style.boxShadow = '0 0 6px 2px #465130')}
                                    onMouseLeave={(e) => (e.target.style.boxShadow = '0 0 3px 1px #465130')} // Revert to default shadow on mouse leave
                                >
                                    Select Coupon
                                </button>}

                                {/* Show the option to remove the coupon when a coupon is selected */}
                                {selectedCoupon && <button
                                    onClick={() => removeCoupon()}
                                    className="w-5/12 md:w-2/5 lg:w-full text-sm md:text-xl bg-tertiary text-secondary font-semibold py-2 px-4 md:px-6 rounded-full hover:font-bold hover:scale-105 duration-500 transition-all"
                                    style={{
                                        boxShadow: '0 0 3px 1px #465130', // Default shadow with color #465130
                                    }}
                                    onMouseEnter={(e) => (e.target.style.boxShadow = '0 0 6px 2px #465130')} // Hover shadow color #F6FAEA
                                    onMouseLeave={(e) => (e.target.style.boxShadow = '0 0 3px 1px #465130')} // Revert to default shadow on mouse leave
                                >
                                    Remove Coupon
                                </button>}

                                {/* Payment Button */}
                                <button
                                    onClick={() => handleMembershipButton(receiptInfo.months, totalAmount.toFixed(2), receiptInfo.mrp)}
                                    className="w-5/12 md:w-2/5 lg:w-full text-sm md:text-xl bg-primary text-tertiary font-semibold py-2 pxpx-4 md:-6 rounded-full hover:font-bold hover:scale-105 duration-500 transition-all"
                                    style={{
                                        boxShadow: '0 0 3px 1px #465130', // Default shadow with color #465130 (primary)
                                    }}
                                    onMouseEnter={(e) => (e.target.style.boxShadow = '0 0 6px 2px #465130')} // Hover shadow color #F6FAEA (tertiary)
                                    onMouseLeave={(e) => (e.target.style.boxShadow = '0 0 3px 1px #465130')} // Revert to default shadow on mouse leave (primary)
                                >
                                    Pay ₹{totalAmount.toFixed(2)}
                                </button>
                            </div>

                            {/* Align the bottom line with SelectedCoupon */}
                            <p className="text-xs sm:text-sm text-center text-tertiary  mb-0">
                                Secured by RazorPay
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Coupon Selection Modal */}
            {showCouponModal &&
                <CouponModal
                    handleCouponSelection={handleCouponSelection}
                    setShowCouponModal={setShowCouponModal}
                    duration={receiptInfo.months}
                />
            }
        </>
    );
};

export default PaymentConfirmationModal;





// /**
//      * removeCoupon
//      * Removes the applied coupon and resets the total amount to the original amount to pay.
//      */
// const removeCoupon = () => {
//     setTotalAmount(receiptInfo.amountToPay);
//     setSelectedCoupon(null);
// };

// /**
//  * handleDiscountCodeSubmit
//  * Checks the validity of the discount code entered by the user.
//  * If valid, apply the discount; otherwise, show an alert.
//  */
// const handleDiscountCodeSubmit = async () => {
//     const discountCoupon = await checkDiscountCodeValidity(discountCode);
//     console.log('code : ', discountCoupon);
//     if (discountCoupon.type) {
//         let discount = discountCoupon.type === 'percentage'
//             ? Math.min((discountCoupon.value / 100) * receiptInfo.amountToPay, discountCoupon.maxDiscount)
//             : discountCoupon.value;
//         setTotalAmount(receiptInfo.amountToPay - discount);
//         setSelectedCoupon(discountCoupon);
//     } else {
//         alert('This code doesn\'t exist or is expired.');
//     }
// };