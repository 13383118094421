import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Sidebar from '../components/resultPage/Sidebar';
import SearchedGymCard from '../components/resultPage/SearchedGymCard';
import { getSearchedGyms } from '../helperFunctions/MarketplaceHelpers/MarketplaceHelper';
import { ListCard } from '../helperFunctions/MarketplaceHelpers/ButtonsLibrary';
import icon from '../../src/assets/Icon.jpeg'

const SearchResult = () => {
    const [searchParams] = useSearchParams();
    const [gyms, setGyms] = useState([]); // Store all fetched gyms
    const [filteredGyms, setFilteredGyms] = useState([]); // Store filtered gyms
    const search = searchParams.get('search');
    const budget = searchParams.get('budget');
    const facilities = searchParams.get('facilities');
    const city = 'delhi';  // Hardcoded city for now, can be dynamic later

    useEffect(() => {
        // Fetch all gyms based on the city
        const fetchData = async () => {
            const res = await getSearchedGyms(city);
            setGyms(res); // Store the full list of gyms
        };
        fetchData();
    }, [city]);

    useEffect(() => {
        // Function to filter gyms based on search, budget, and facilities
        const filterGyms = () => {
            let filtered = gyms;
    
            // Filter by search query
            if (search) {
                filtered = filtered.filter(gym => {
                    const searchLower = search.toLowerCase();
    
                    // Check gym name and locality
                    const matchesGymDetails = (gym.gymDisplayName && gym.gymDisplayName.toLowerCase().includes(searchLower)) ||
                                               (gym.locality && gym.locality.toLowerCase().includes(searchLower));
    
                    // Check if any facility name matches the search query
                    const matchesFacilities = gym.facilities && Object.keys(gym.facilities).some(facility => 
                        gym.facilities[facility] === true && facility.toLowerCase().includes(searchLower)
                    );
                    return matchesGymDetails || matchesFacilities;
                });
            }
    
            // Filter by budget
            if (budget) {
                const budgetValue = parseInt(budget, 10);
                filtered = filtered.filter(gym => {
                    const gymPrice = gym.exclusiveMonthlyPrice; // Assume gym has a price field
    
                    if (budgetValue === 1) return gymPrice < 1500;
                    if (budgetValue === 2) return gymPrice >= 1500 && gymPrice <= 3000;
                    if (budgetValue === 3) return gymPrice > 3000 && gymPrice <= 4500;
                    if (budgetValue === 4) return gymPrice > 4500;
    
                    return true; // Default case if budget doesn't match any known values
                });
            }
    
            // Filter by facilities
            if (facilities) {
                const facilityArray = facilities.split(','); // Convert facilities string into an array
    
                filtered = filtered.filter(gym => {
                    // Check if gym's required facilities are true
                    return facilityArray.every(facility =>
                        gym.facilities && gym.facilities[facility.trim()] === true
                    );
                });
            }
    
            setFilteredGyms(filtered);
        };
    
        if (gyms.length > 0) {
            filterGyms();
        }
    }, [gyms, search, budget, facilities]);
    
    return (
        <div className='pt-32 pb-5 flex justify-between lg:px-20 px-5 gap-5 min-h-[calc(100vh-200px)] bg-lightTertiary lg:flex-row flex-col'>
            <Sidebar />

            <div className='w-full flex lg:py-5 flex-col'>
                <div className='text-2xl flex gap-2'>
                    Results for : <div className='font-semibold'>{search} in {city}</div>
                </div>
                <div className='flex flex-col lg:gap-5 lg:py-5'>
                    {filteredGyms.length > 0 ? (
                        filteredGyms.map((card, index) => (
                            // <SearchedGymCard key={index} gym={card} />
                            <ListCard
                            key={index}
                            image={card.image || [icon, icon, icon]}
                            name={card.gymName}
                            city={card.city}
                            gymDisplayName={card.gymDisplayName}
                            rating={card.rating}
                            NoOfReview={card.NoOfReview}
                            locality={card.locality}
                            buttonText={"EXPLORE"}
                            extraText={"Free Trials | Membership deals Available"}
                            stars={true}
                            // To be fixed in future
                            price={card.exclusiveMonthlyPrice*10  || card.prices.single.exclusiveMonthlyPrice*12}
                            facilities={card.facilities}
                            logo={card.logo}
                            />
                        ))
                    ) : (
                        <div>No gyms found for "{search}" in {city}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SearchResult;
