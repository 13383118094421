import { collection, doc, getDoc, setDoc, updateDoc, increment, query, where, orderBy, limit, getDocs } from "@firebase/firestore";
import { db } from "../../../config/firebase";
import { cityList, gymList } from "../../../components/Databases";
import { getCurrentMonth, getCurrentMonthAndYear, getTime } from "../../basicHelper";
import { getUserCity } from "../../LocationHelpers";

/**
 * Checks if The Lead has been created within an hour or not
 * @param {String} timestamp
 * @returns {Bool}             -    True, if yes; false otherwise
 */
const isLessThanOneHourAgo = (timestamp) => {
    const oneHour = 60; // 1 hour in minutes
    const now = new Date().getTime(); // Get the current time in milliseconds
    const leadTime = new Date(timestamp).getTime(); // Convert Firestore timestamp to milliseconds
    const difference = now - leadTime; // Calculate the difference

    // Convert difference to minutes (for better understanding)
    const differenceInMinutes = difference / (60 * 1000); // Convert milliseconds to minutes

    // Return true if the lead was created less than one hour ago
    return differenceInMinutes < oneHour;
};


// Generates random string to store in the database as temporary user's id for leadInfo document
function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) 
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    
    return result;
}

let latestTimestamp; // declared outside both the functions because both of them are using the same variable but incrementLeads() is updating its value inside

/**
 * 
 * @param {Object} user     - Contains user information (can be undefined / blank)
 * @param {Object} gymData  - Contains gym information
 * @returns {Boolean}       - true if storing successful; false otherwise
*/
export const leadGeneration = async (user, gymData) => {
    try {
        const userCity = await getUserCity();
        const monthName = getCurrentMonth();
        const currentMonthaAndYear = getCurrentMonthAndYear();
        const gymRef = doc(db, cityList, gymData.city, gymList, gymData.gymName, 'Leads', currentMonthaAndYear);
        const gymDoc = await getDoc(gymRef);

        let toUpdate = {
            signedIn          : user ? true : false,
            userCity          : userCity.toLowerCase(),
            userName          : user ? user.displayName : "",
            tempUser          : user ? "" : generateRandomString(12),
            phoneNumber       : user ? user.phoneNumber : "",
            email             : user ? user.email : "",
            boughtMembership  : false,
            contacted         : false,   // added these as they are needed in the sales page in Train Rex dashboard (website repository)
            interested        : false,   // added these as they are needed in the sales page in Train Rex dashboard (website repository)
            converted         : false,   // added these as they are needed in the sales page in Train Rex dashboard (website repository)
            timestamp         : getTime(),
        };

        await increamentLeads(user, gymRef, gymDoc, monthName);

        // Always adds a document in the LeadInfo subcollection with user information
        if(user && !isLessThanOneHourAgo(latestTimestamp)) {    
            // if user is signed in and last document is generated more than an hour ago
            const leadInfoRef = collection(gymRef, 'LeadInfo');
            await setDoc(doc(leadInfoRef), toUpdate); 
        } else if(!user) {        
            // if user is using guest mode, document will be generated regardless of anything
            const leadInfoRef = collection(gymRef, 'LeadInfo');
            await setDoc(doc(leadInfoRef), toUpdate);
        }

        return true; // this true is not getting used anywhere except for console logs
    } catch (error) {
        // console.error('Error in leadGeneration:', error);
        return false; // this false is not getting used anywhere except for console logs
    }
};

// increments totalLeads field in the Leads collection of the gym
const increamentLeads = async (user, gymRef, gymDoc, monthName) => {
    try {
        if (user) {
            // Check if the user has generated a lead in the past hour
            const leadInfoRef   = collection(gymRef, 'LeadInfo');
            const q             = query(leadInfoRef, where("email", "==", user.email), orderBy("timestamp", "desc"), limit(1));
            const querySnapshot = await getDocs(q);

            let canIncrementLeads = true;

            if (!querySnapshot.empty) {
                const latestLeadDoc = querySnapshot.docs[0];
                latestTimestamp = latestLeadDoc.data().timestamp;

                // Check if the latest lead was created less than an hour ago
                if (isLessThanOneHourAgo(latestTimestamp)) {
                    canIncrementLeads = false;
                }
            }

            // Only increment totalLeads if no lead was generated in the last hour
            if (canIncrementLeads) {
                if (gymDoc.exists()) 
                    await updateDoc(gymRef, { totalLeads: increment(1) });
                else 
                    await setDoc(gymRef, { monthName: monthName, totalLeads: 1, converted: 0 });
            } 

        } else {
            // For new (unsigned) users: Always increment totalLeads
            if (gymDoc.exists() && (Object.keys(gymDoc.data()).length > 0)) { // this Object.keys(gymDoc.data()).length > 0 check if there are any fields present in the gymDoc
                await updateDoc(gymRef, { totalLeads: increment(1) });
            } else {
                await setDoc(gymRef, { monthName: monthName, totalLeads: 1, converted: 0 });
            }
        }
    } catch (error) {
        // console.error('Error in incrementing leads : ', error);
    }
}
