/**
 * Returns the City of the user
 * Based on their IP Address
 * @returns {String} - The City user's IP is from
 */
export const getUserCity = async () => {
    try {
        const response = await fetch('https://ipwhois.app/json/');
        const data = await response.json();
        if (data && data.city) {
            // console.log('User City:', data);
            return data.city;
        } else {
            // console.error('City not found');
            return null;
        }
    } catch (error) {
        // console.error('Error fetching city from IP:', error.message);
        return null;
    }
}