import React, { useEffect, useState } from 'react';
import StarRating from '../../components/StarRating';
import { BigButton } from '../MarketplaceHelpers/ButtonsLibrary';
import { getDaysAgo } from '../MarketplaceHelpers/gymDataHelpers/GymReviewsHelper';
import verified from "../../assets/Verified.png"
import { fetchGymReviews } from '../MarketplaceHelpers/gymDataHelpers/HandlingReviews';
import AddReviewModal from '../AddReviewModal';

const RatingAndReviews = ({
    visibleReviews,
    showReviewModal,
    setShowReviewModal,
    isUserPresent,
    currGymData,
    user,
    isUserRecent,
    showAddReviewModal,
    sampleReviews,
    showMoreReviews,
    showLessReviews,
}) => {

    const [reviews, setReviews] = useState({});
    const [averageRating, setAverageRating] = useState();
    const [ratingCounts, setRatingCounts] = useState();

    // to fetch gym reviews
    useEffect(() => {
    const fetchReviews = async () => {
        try {
            const fetchedReviews = await fetchGymReviews(currGymData);
            setReviews(fetchedReviews);

            // Calculate average rating
            if (fetchedReviews.length > 0) {
                const totalRating = fetchedReviews.reduce((acc, review) => acc + review.rating, 0);
                const averageRating = totalRating / fetchedReviews.length;
                setAverageRating(averageRating.toFixed(1)); // Set average rating and round to 1 decimal place
            } else {
                setAverageRating(0); // If no reviews, set average rating to 0
            }

            getRatingCounts(reviews);

        } catch (error) {
            // console.error("Error fetching reviews:", error);
        }
    };

    fetchReviews();
}, [currGymData]);

    return (
        <div className="flex flex-col justify-start lg:border-4 border-secondary rounded-2xl lg:w-full lg:h-[700px]">
            <div className="flex flex-col lg:flex-row justify-between items-left mr-2">
                <p className="flex lg:px-4 py-4 pb-2 text-secondary text-xl lg:text-3xl font-bold text-left">
                    Rating and Reviews
                </p>

                <div className="flex flex-col lg:px-4 py-2">
                    {ratingCounts && Object.keys(ratingCounts).reverse().map(star => (
                        <div key={star} className="flex items-center justify-between -mb-1 cursor-default">
                        <StarRating rating={Number(star)} showNoOfReviews={false} color="secondary" />
                        <p className="ml-2 text-secondary text-sm lg:text-lg font-semibold">
                            : {ratingCounts[star]}
                        </p>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex lg:mx-4 border-t-2 border-secondary lg:my-4"></div>

            <div className="flex flex-col lg:px-4 relative h-full overflow-y-scroll">
                {reviews.length > 0 ? (
                    <>
                        {Array.isArray(reviews) && reviews.slice(0, visibleReviews).map((review, index) => (
                        <ReviewItem key={index} review={review} />
                        ))}
                        <ReviewActions
                            showAddReviewModal={showAddReviewModal}
                            setShowReviewModal={setShowReviewModal}
                            isUserPresent={isUserPresent} 
                            currGymData={currGymData}
                            user={user} 
                            isUserRecent={isUserRecent} 
                            visibleReviews={visibleReviews}
                            showMoreReviews={showMoreReviews}
                            showLessReviews={showLessReviews}
                            NoOfReview={currGymData.NoOfReview}
                        />
                        {showAddReviewModal && isUserPresent ? (
                            <AddReviewModal setShowReviewModal={setShowReviewModal} gymLocation={currGymData.city} gymName={currGymData.gymName} isRecentGymUser={isUserRecent} displayName={user.displayName} />
                        ) : null}
                    </>
                ) : (
                    <NoReviews 
                        showAddReviewModal={showAddReviewModal}
                        setShowReviewModal={setShowReviewModal} 
                        isUserPresent={isUserPresent} 
                        currGymData={currGymData} 
                        user={user} 
                        isUserRecent={isUserRecent} 
                    />
                )}
            </div>
        </div>
    );
};

const ReviewItem = ({ review }) => (
    <div className="flex flex-col mb-4">
        <div className="flex flex-row justify-between py-2">
            <p className="flex text-secondary text-sm lg:text-2xl font-bold">
                {review.userName}
            </p>
            <div className="flex">
                <StarRating rating={review.rating} color={'secondary'} showNoOfReviews={false} />
            </div>
        </div>
        <div className="flex -mt-2 mb-2">
            <div className="text-left text-primary text-xs">
                {getDaysAgo(review.timestamp)}
            </div>
            {review.verified ? (
                <>
                    <div className="text-base -mt-1 px-1 text-primary"> | </div>
                    <span className="text-xs text-secondary mr-1 font-semibold -mb-1">Verified by Train Rex</span>
                    <img src={verified} alt="Trusted" className="h-4 w-4" />
                </>
            ) : null}
        </div>
        <p className="flex text-black text-sm lg:text-lg text-left whitespace-pre-line">
            {review.review}
        </p>
        <div className="flex border-t-[1px] border-secondary mt-4"></div>
    </div>
);

const ReviewActions = ({ setShowReviewModal, visibleReviews, showMoreReviews, showLessReviews, NoOfReview }) => (
    
    <div className="flex justify-between lg:justify-center pb-8 lg:pb-2">
        {visibleReviews < NoOfReview ? (
            <BigButton
                label="View More"
                bg="bg-secondary"
                textColor="text-tertiary"
                width="lg:w-1/7"
                onClick={showMoreReviews}
            />
        ) : (
            <BigButton
                label="View Less"
                bg="bg-secondary"
                textColor="text-tertiary"
                width="lg:w-1/7"
                onClick={showLessReviews}
            />
        )}
        <BigButton
            label="Add Review"
            bg="bg-secondary"
            textColor="text-tertiary"
            width="w-1/7 flex justify-center"
            onClick={() => setShowReviewModal(true)} // Open modal on button click
        />
    </div>
);

const NoReviews = ({ showAddReviewModal, setShowReviewModal, isUserPresent, currGymData, user, isUserRecent }) => (
    <div className="relative h-32">
        {/* {sampleReviews.slice(0, visibleReviews).map((review, index) => (
        <div key={index} className="flex flex-col mb-4">
            <div className="flex flex-row gap-x-4 py-2">
            <p className="flex text-secondary text-sm lg:text-xl font-bold">
                {review.name}
            </p>
            <div className="flex">
                {Number.isInteger(parseInt(review.rating)) &&
                [...Array(parseInt(review.rating))].map((_, starIndex) => (
                    <span key={starIndex} className="text-sm lg:text-xl text-secondary">&#9733;</span>
                ))}
            </div>
            </div>
            <p className="flex text-black text-sm lg:text-lg text-left">
            {review.review}
            </p>
            <div className="flex border-t-[1px] border-secondary mt-4"></div>
        </div>
        ))} */}
        <div className="absolute inset-0 bg-tertiary bg-opacity-100 items-center justify-center text-secondary text-lg lg:text-2xl font-bold flex flex-col space-y-4 rounded-xl">
            <p>Be the first one to review</p>
            <div className="flex justify-between lg:justify-center pb-8 lg:pb-2">
                <BigButton
                    label="Add Review"
                    bg="bg-secondary"
                    textColor="text-tertiary"
                    width="w-1/7 flex justify-center"
                    onClick={() => setShowReviewModal(true)} // Open modal on button click
                />
            </div>
        </div>
        {showAddReviewModal && isUserPresent ? (
            <AddReviewModal setShowReviewModal={setShowReviewModal} gymLocation={currGymData.city} gymName={currGymData.gymName} isRecentGymUser={isUserRecent} displayName={user.displayName} />
        ) : null}
    </div>
);

// Getting count of 5,4,3,2,1 stars rating
const getRatingCounts = (reviews) => {
    const counts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
    reviews.forEach(review => {
        const rating = review.rating;
        if (rating >= 1 && rating <= 5) {
            counts[rating]++;
        }
    });
    return counts;
};

export default RatingAndReviews;
