export const equipmentCategoryList = {
	chest: [
		'cablecrossover',
		'chestpress',
		'declinebench',
		'declinepress',
		'flatbench',
		'inclinebench',
		'peckdeckfly',
		'smithmachine', // Often used for chest exercises like bench press
		'butterfly',
        'olympicdeclinebench', // Added
        'olympicflatbench', // Added
        'olympicinclinebench' // Added
	],
	back: [
		'backrollmachine',
		'cablerowmachine',
		'hyperextension',
		'latpulldown',
		'pullupbar',
		'rowingmachine',
		'seatedrowmachine',
        'seated-rowmachine',
		'trapbar', // Used for deadlifts which target the back
        'midrowmachine', // Added
        'mid-rowmachine'
	],
	arms: [
		'preachercurlmachine',
        'preachercurlmachine/tricepextension',
		'ezcurlbar', // Added, often used for curls
		'cablecurlmachine', // Common for bicep curls
        'preacherbench', // Added
        'wristcurl', // Added
        'preachercurlmachine' // Duplicate added under arms for curls/triceps
	],
	shoulders: [
		'shoulderpress',
		'peckdec', // Alternate name: pec deck
		'lateralraisemachine',
		'militarypressmachine', // Common for shoulder workouts
		'arnoldpress', // Popular shoulder exercise with dumbbells
		'lateralraise' // Added
	],
	legs: [
		'abductionandadductionmachine',
        'abductionandadduction',
		'adductormachine',
		'calfmachine',
		'hacksquatmachine',
		'hyperextension',
		'legcurlmachine',
        'legcurl',
		'legextension',
		'legpress',
		'squatbar',
		'hipthrustmachine', // Common for glutes/legs
		'glutemachine', // Specific machine targeting glutes
		'sledmachine', // Often used for pushing exercises
        'halfrackwithplatform', // Added
        'hacksquatmachine', // Added
        'preacherbench', // Added
	],
	cardio: [
		'battlerope',
		'stationarybicycle',
		'treadmill',
		'tyre', // Typically used for tire flips, functional exercise
		'rowingmachine', // Used as both cardio and back exercise
		'stairclimber', // Popular cardio machine
        'cross trainer', // Added
        'airbike', // Added
        'skiergmachine', // Added
        'inclinetrainer' // Added
	],
	common: [
		'dumbbells',
        'dumbells', // Added alternative spelling
		'smithmachine', // Can also be used for shoulders, back, legs
		'kettlebells', // Very common for various exercises
		'barbells', // Used in almost every part of body workouts
		'pullupbar' // Useful for back and arms
	],
	special: [
		'infinitemachine',
		'wallclimbing',
		'versaclimber', // Often found in specialized gyms
		'climbingropes', // Common in CrossFit and functional gyms
		'armwrestlingplatform',
        'bca', // Added special machine
        'mi7', // Added
        'dualtwister', // Added
        'chin/dipassist', // Added
        'hipthrust', // Added
        'abs machine' // Added
	],
};


// Facilities with order of precedence
export const importantFacilities = [
    'certifiedtrainers',
    'valetparking',
    'personaltrainer',
    'sauna',
    'steambath',
    'airconditioned',
    'fitnesscounselling',
    'dietcounselling',
    'loungeandcafe',
];

// Classes with order of precedence
export const importantClasses = [
    'aerobics',
    'functionaltraining',
    'hiit',
    'pilates',
    'zumba',
]