import React from 'react';
import { BigButton } from '../MarketplaceHelpers/ButtonsLibrary';
import verified from "../../assets/Verified.png";
import StarRating from '../../components/StarRating';

const GymInfoSection = ({ currGymData, scrollToSection }) => {
    return (
        <div className="flex flex-col justify-between">
            {/* Gym Name and Verified Badge */}
            <div className="flex flex-col lg:flex-row justify-between pb-2">
                <div className="flex flex-col">
                    <div className="flex items-center gap-4">
                        <p className="text-2xl md:text-4xl lg:text-6xl font-bold text-secondary text-left">
                            {currGymData?.gymDisplayName || currGymData.name}
                        </p>
                        {currGymData.trainRexVerified && (
                            <div className="relative group">
                                <img 
                                    src={verified} 
                                    className="h-8 w-8 md:h-14 md:w-14 rounded-full" 
                                    alt="Verified Gym" 
                                />
                                <span className="hidden lg:flex absolute w-max bg-secondary text-logoGray text-xs md:text-sm rounded py-1 px-2 bottom-full transform -translate-x-1/2 translate-y-[-10px] opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    Train Rex Verified Gyms allow a seamless entry experience & more
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="flex pt-2">
                        <p className="text-lg md:text-2xl lg:text-4xl font-bold text-secondary">
                            {currGymData?.cityDisplayName}
                        </p>
                    </div>
                </div>

                {/* Review and Ratings */}
                <div className="flex flex-col items-end">
                    <div className="hidden lg:flex flex-col">
                        <div className="text-lg lg:text-3xl text-secondary font-bold">
                            <StarRating
                                rating={currGymData?.rating} 
                                NoOfReview={currGymData?.NoOfReview} 
                                color={'secondary'} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Gym Address and Timings */}
            <div className="flex flex-col lg:flex-row justify-between">
                <div className="flex flex-col py-4 text-left">
                    {/* Address */}
                    <p className="text-sm md:text-lg font-bold text-black">Address</p>
                    <p className="text-sm md:text-lg font-bold text-secondary">{currGymData?.address}</p>
                    <p className="text-sm md:text-lg font-bold text-secondary">{currGymData?.locality},</p>
                    <p className="text-sm md:text-lg font-bold text-secondary">
                        {currGymData?.cityDisplayName}, {currGymData?.pinCode}
                    </p>

                    {/* Timings */}
                    <div className="flex flex-col mb-4">
                        <p className="text-sm md:text-lg font-bold text-black">Timings</p>
                        {currGymData?.timeSlots?.length > 0 ? (
                            currGymData.timeSlots.map((slot, index) => (
                                <p key={index} className="text-sm md:text-lg font-bold text-secondary">
                                    {slot.openingTime} to {slot.closingTime}
                                </p>
                            ))
                        ) : (
                            <p className="text-sm md:text-lg font-bold text-secondary">
                                {currGymData?.openingTime} to {currGymData?.closingTime}
                            </p>
                        )}
                    </div>
                </div>

                {/* Call to Action Buttons */}
                {/* Uncomment this section if needed */}
                {/* 
                <div className="flex flex-col bg-secondary px-4 pt-2 rounded-2xl items-center mt-4 lg:mt-0">
                    <p className="text-sm font-semibold text-black p-2">
                        Liked {currGymData?.gymDisplayName}?
                    </p>
                    <BigButton
                        label="BOOK A FREE TRIAL"
                        bg="bg-tertiary"
                        textColor="text-secondary"
                        width="w-full"
                    />
                    <BigButton
                        label="BUY MEMBERSHIP"
                        onClick={() => scrollToSection("membership-section")}
                        bg="bg-tertiary"
                        textColor="text-secondary"
                        width="w-full"
                    />
                    <BigButton
                        label={`Call ${currGymData?.phoneNumber}`}
                        bg="bg-tertiary"
                        textColor="text-secondary"
                        width="w-full"
                        className={`${currGymData?.phoneNumber ? 'block' : 'hidden'}`}
                    />
                </div>
                */}
            </div>
        </div>
    );
};

export default GymInfoSection;
