import React from 'react';
import '../../App.css';
import { ListCard, PillButton, Placard } from '../../helperFunctions/MarketplaceHelpers/ButtonsLibrary';
import icon from '../../assets/Icon-512.png'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";

function Careers() {
    const location = useLocation();

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Marketplace', {
            page_path: location.pathname,
        });
    }, [location]);
    
    return (
        <div className="bg-tertiary flex flex-col px-4 lg:px-48 pb-44">
            {/* <div className="flex flex-row lg:py-4">
                <PillButton label="Gyms/Fitness Centres" list={true}/>
                <PillButton label="Fitness Classes" list={true}/>
                <PillButton label="Sports" list={true}/>
            </div> */}
            <div className="flex flex-col py-4">
                <p className="flex text-secondary text-lg lg:text-4xl font-bold px-2">JOBS</p>
                <p className="flex text-secondary text-sm lg:text-2xl font-bold px-2">INTENT MATTERS</p>
                <div>
                    <ListCard 
                        image= {[icon]}
                        name= "Field Marketing Agent" 
                        location= "Edinburgh, UK"
                        buttonText= "Apply"
                        extraText= "Jobs"/>
                </div>
                <p className="flex text-secondary text-sm lg:text-2xl font-bold"></p>
                
            </div>
        </div>
    );
}

export default Careers;