import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet, useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Import Screens
import HomePage from './Screens/AppDescription/HomePage';
import Marketplace from './Screens/marketplace/Marketplace';
import Listing from './Screens/marketplace/Listing';
import ProductPortfolio from './Screens/marketplace/ProductPortfolio';
import TCPage from './Screens/AppDescription/TCPage';
import PPPage from './Screens/AppDescription/PPPage';
import GymForm from './Screens/GymForm';
import GymCred from './Screens/GymCred';
import BlogPost from './Screens/marketplace/BlogPost';
import BlogList from './Screens/marketplace/BlogList';
import WriteBlog from './Screens/marketplace/WriteBlog';
import Careers from './Screens/AppDescription/careers';

// Import Components
import NavBar_Marketplace from "./components/NavBar_Marketplace";
import NavBar_Home from "./components/NavBar";
import BottomBar from "./components/BottomBar";
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";
import './index.css';
import { getLocation } from './helperFunctions/MarketplaceHelpers/MarketplaceHelper';
import NavBar_Marketplace_helper from './helperFunctions/Navbar_helpers/Navbar_MarketPlace_helper';
import Navbar_App_helper from './helperFunctions/Navbar_helpers/Navbar_App_helper';
import GymPage from './Screens/marketplace/GymPage';
import SearchResult from './Screens/SearchResult';
import EventContact from './Screens/AppDescription/eventcontact';

/**
 * Layout component for Marketplace-related pages
 */
const MarketplaceLayout = ({ selectedCity, onLocationChange }) => (
    <>
        <NavBar_Marketplace_helper selectedCity={selectedCity} onLocationChange={onLocationChange} />
        <Outlet />
    </>
);

/**
 * Layout component for Train Rex App-related pages.
 */
const TrainRexLayout = () => (
    <>
        <Navbar_App_helper />
        <Outlet />
    </>
);

function App() {
    const [selectedCity, setSelectedCity] = useState(() => {
        return localStorage.getItem('selectedCity') || 'NCR';  // Default to 'delhi'
    });

    // Log an event to Firebase Analytics when the user enters the app
    useEffect(() => {
        const currentTime = new Date().toISOString();
        const userAgent = navigator.userAgent;
        logEvent(analytics, `${userAgent}, ${currentTime}`);
    }, []);

    // Update selectedCity when location changes
    const handleLocationChange = (city) => {
        setSelectedCity(city);
        localStorage.setItem('selectedCity', city);
    };

    return (
        <HelmetProvider>
            <div className="App">
                <Routes>
                    {/* Marketplace routes with the Marketplace layout */}
                    <Route element={<MarketplaceLayout selectedCity={selectedCity} onLocationChange={handleLocationChange} />}>
                        <Route path="/" element={<Marketplace selectedCity={selectedCity}/>} />
                        <Route path="/:city" element={<Marketplace selectedCity={selectedCity}/>} />
                        <Route path="/:city/:gyms" element={<CityWrapper component={Listing} />} />
                        <Route path="/ProductPortfolio" element={<ProductPortfolio selectedCity={selectedCity} />} />
                        <Route path="/blogs" element={<BlogList selectedCity={selectedCity} />} />
                        <Route path="/blogs/:blogSlug" element={<BlogPost selectedCity={selectedCity} />} />
                        <Route path="/WriteBlog" element={<WriteBlog />} />
                        <Route path="/BusinessSignUp" element={<GymForm />} />
                        <Route path="/Credentials" element={<GymCred />} />
                        <Route path="/:city/:gyms/:gymSlug" element={<CityWrapper component={GymPage} />} />
                        <Route path="/:city/result" element={<SearchResult />} />
                        <Route path="/event" element={<EventContact />} />

                    </Route>

                    {/* Train Rex App routes with the TrainRex layout */}
                    <Route element={<TrainRexLayout />}>
                        <Route path="/AppDescription" element={<HomePage />} />
                        <Route path="/AppDescription/Terms&Conditions" element={<TCPage />} />
                        <Route path="/AppDescription/PrivacyPolicy" element={<PPPage />} />
                        <Route path="/AppDescription/Careers" element={<Careers />} />
                    </Route>
                </Routes>

                {/* Render the BottomBar component */}
                <BottomBar />
            </div>
        </HelmetProvider>
    );
}

/**
 * CityWrapper Component
 * A wrapper component to sync the city from the URL with the selectedCity state.
 * @param {Object} props - The props object.
 * @param {JSX.Element} props.component - The component to render.
 * 
 * @returns {JSX.Element} The rendered component with city synced from the URL.
 */
const CityWrapper = ({ component: Component }) => {
    const { city } = useParams(); // Capture city from URL
    const [selectedCity, setSelectedCity] = useState(() => {
        return localStorage.getItem('selectedCity') || city;
    });

    useEffect(() => {
        // Sync the city from the URL with the state and localStorage
        if (city) {
            setSelectedCity(city);
            localStorage.setItem('selectedCity', city);
        }
    }, [city]);

    return <Component selectedCity={selectedCity} />;
};

export default App;
