import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import '../../App.css';
import { NearYou } from '../../helperFunctions/MarketplaceHelpers/NearYou';
import { themeColors } from '../../theme';
import PictureAndSearchFiltering from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/PictureAndSearchFiltering';
import Tagline from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Tagline';
import ContactUs from '../../helperFunctions/MarketplaceHelpers/ContactUs';
import { Blogposts } from '../../helperFunctions/MarketplaceHelpers/Blogposts';
import { GetTheApp } from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/GetTheApp';
import Map from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Map';
import { removeSpacesAndConvertLowercase } from '../../helperFunctions/basicHelper';
import { getAllClasses } from '../../helperFunctions/ClassesHelpers/gettingClasses';
import { analytics } from '../../config/firebase';
import { logEvent } from 'firebase/analytics';
import CTACarousel from './CTACarousel';

// Custom hook to handle scroll-based fade-in and fade-out based on scroll direction
const useScrollFadeInOut = () => {
    const [isVisible    , setIsVisible    ] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const elementRef                        = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null, // The viewport
            rootMargin: "0px",
            threshold: [0.20, 0.25] // Trigger between 5% and 25% of the component's visibility
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

                // If scrolling down and component is visible -> fade in
                if (entry.isIntersecting && currentScrollTop > lastScrollTop) {
                    setIsVisible(true);
                }
                // If scrolling up and component is out of view -> fade out
                else if (!entry.isIntersecting && currentScrollTop < lastScrollTop) {
                    setIsVisible(false);
                }

                // Update last scroll position
                setLastScrollTop(currentScrollTop);
            });
        }, observerOptions);

        const currentElement = elementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [lastScrollTop]);

    return { ref: elementRef, isVisible };
};

// Wrapper component to apply the fade effect to children components
const ComponentWithFadeInOut = ({ children }) => {
    const { ref, isVisible } = useScrollFadeInOut();

    return (
        <div ref={ref} className={`fade-section ${isVisible ? 'fade-in' : 'fade-out'}`}>
            {children}
        </div>
    );
};

function Marketplace({ selectedCity }) {
    const location = useLocation();
    const [classList, setClassList] = useState(null);
    const [loading  , setLoading  ] = useState(null);

    useEffect(() => {
        setLoading(true);
        const fetchGymsAndClasses = async () => {
            const classes = await getAllClasses(selectedCity);
            setClassList(classes);
            setLoading(false);
        };
        fetchGymsAndClasses();
    }, [selectedCity]);

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Marketplace', {
            page_path: ["Home", selectedCity],
        });
    }, [location]);

    return (
        <>
            {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
            <Helmet>
                <title>Explore Gyms and Fitness Centers in {selectedCity} | Verified Gym Memberships - Train Rex</title>
                <meta
                    name="description"
                    content={`Find top gyms, fitness centers, and workout classes in ${selectedCity}. Discover the best fitness options and join the fitness community with Train Rex.`}
                />
                <meta
                    name="keywords"
                    content={`best gyms in Delhi, best zumba classes in Delhi, 
                        best gyms in Ghaziabad, fitness classes in Ghaziabad, 
                        gyms in Noida, fitness classes in Noida, 
                        best gyms in Delhi NCR, best fitness classes in Delhi NCR, 
                        fitness centers, fitness marketplace, health and wellness, holistic fitness solution, gym memberships, improve fitness journey, motivational fitness app, health and fitness ecosystem, Train Rex`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/marketplace/${selectedCity}`} />
            </Helmet>

            <div className="bg-tertiary w-full pt-16 lg:pt-28">
                {/* <PictureAndSearchFiltering /> */}
                <div className="w-full">
                    <CTACarousel selectedCity={selectedCity}/>
                </div>

                <Tagline />

                {/* Fitness centers section */}
                
                <NearYou
                        heading="CHECK OUT FITNESS CENTRES NEAR YOU"
                        headingMobile="FITNESS CENTRES NEAR YOU"
                        bg="bg-tertiary"
                        headingColor="text-secondary"
                        size="mx-auto lg:mx-40"
                        locationIconColor={themeColors.lightAccent}
                        selectedCity={removeSpacesAndConvertLowercase(selectedCity)}
                    />

                {/* Map Section */}
                {/* <ComponentWithFadeInOut> */}
                    <Map selectedCity={selectedCity} currentPage="marketplace" />
                {/* </ComponentWithFadeInOut> */}

                {/* Get the TrainRex App */}
                <ComponentWithFadeInOut>
                    <GetTheApp />
                </ComponentWithFadeInOut>

                {/* Blog List */}
                <ComponentWithFadeInOut>
                    <Blogposts selectedCity={removeSpacesAndConvertLowercase(selectedCity)} />
                </ComponentWithFadeInOut>

                {/* Contact Us Section */}
                <ComponentWithFadeInOut>
                    <ContactUs />
                </ComponentWithFadeInOut>
            </div>
        </>
    );
}

export default Marketplace;
