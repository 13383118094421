import React, { useEffect, useState } from 'react';
import { getGymMachineryList, topFacilities, segregateEquipment, topClasses } from './GymMachineryHelper';

/**
 * formats the name of the Equipment
 * @param {String} name 
 * @returns {String} - Formatted equipment name with spaces between camel case
 */
export const formatEquipmentName = (name) => {
	return name.replace(/([a-z])([A-Z])/g, '$1 $2');
};

/**
 * GymDetailsComponent is the hero section that displays the gym's equipment,
 * facilities, and available classes. Entry and exit animations are added for
 * enhanced user experience, along with detailed transitions for interactions.
 *
 * @param {Object} gymData - The data containing lists of available equipment, facilities, and classes for a specific gym.
 */
const GymDetailsComponent = ({ gymData }) => {

    // State to store the list of gym equipment
    const [equipmentList, setEquipmentList] = useState([]);
    
    // State to store the list of gym facilities
    const [facilities, setFacilities] = useState([]);
    
    // State to store the list of available gym classes
    const [classes, setClasses] = useState([]);

    // State to control showing all facilities and classes
    const [showAllFacilities, setShowAllFacilities] = useState(false);
    const [showAllClasses, setShowAllClasses] = useState(false);

    // State to track which category is expanded for equipment
    const [expandedCategories, setExpandedCategories] = useState({});

    /**
     * useEffect hook fetches the gym's equipment, facilities, and classes data
     * from the gymData prop whenever it changes. The fetched data is then stored
     * in their respective states.
     */
    useEffect(() => {
        const fetchLists = async () => {
            try {
                // Fetch gym details
                const fetchedList = getGymMachineryList(gymData);

                // Segregate and categorize the equipment using the helper function
                setEquipmentList(segregateEquipment(fetchedList.availableEquipment));

                // Initialize expanded state for categories (default to collapsed)
                const initialExpandedState = Object.keys(equipmentList).reduce((acc, category) => {
                    acc[category] = false;
                    return acc;
                }, {});
                setExpandedCategories(initialExpandedState);

                // Set the facilities list
                setFacilities(topFacilities(fetchedList.availableFacilities));

                // Set the classes list using the topClasses function
                setClasses(topClasses(fetchedList.availableClasses)); // Use topClasses here to sort classes
            } catch (error) {
                console.error("Error fetching gym details:", error);
            }
        };

        fetchLists(); // Trigger fetching of gym data

    }, [gymData]);

    // Function to toggle category expansion for equipment
    const handleToggleCategory = (category) => {
        setExpandedCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category] // Toggle the state for the selected category
        }));
    };

    // Function to toggle visibility of all facilities
    const handleShowAllFacilities = () => {
        setShowAllFacilities(!showAllFacilities);
    };

    // Function to toggle visibility of all classes
    const handleShowAllClasses = () => {
        setShowAllClasses(!showAllClasses);
    };

    // Limit the displayed facilities to the first 5
    const displayedFacilities = showAllFacilities ? facilities : facilities.slice(0, 5);

    // Limit the displayed classes to the first 5
    const displayedClasses = showAllClasses ? classes : classes.slice(0, 5);

    return (
		<section className="relative w-full min-h-screen bg-tertiary py-12 px-4 lg:px-16 overflow-hidden">
			{/* Hero Title Section */}
			<div className="text-center mb-16 animate-fadeIn">
				<h1 className="text-4xl lg:text-6xl font-bold mb-4 text-secondary text-left lg:text-center">Explore Our Gym</h1>
				<p className="text-lg lg:text-2xl text-primary text-left lg:text-center">State-of-the-art equipment, premium facilities, and exciting classes!</p>
			</div>
	
			{/* Three-Column Layout with Flexbox for Equal Alignment */}
			<div className="flex flex-col lg:flex-row justify-start lg:justify-between gap-0 lg:gap-8">
				{/* Equipment Column with Expandable Categories */}
				<div className="flex-1 shadow-md rounded-3xl px-4 py-4 transform lg:hover:scale-110 hover:shadow-xl transition duration-500 ease-in-out animate-fadeIn relative z-10 mt-0 lg:mt-8 mb-20">
					<h2 className="text-xl lg:text-2xl text-left text-primary mb-6">Available Equipment</h2>
					<div className="space-y-2"> {/* Ensure consistent spacing */}
						{Object.keys(equipmentList).map((category) => (
							<div key={category} className="mb-4">
								<div className={`transition-all duration-300 ease-in-out ${expandedCategories[category] ? 'shadow-lg bg-white rounded-3xl p-4' : ''}`}>
									{/* Category Header inside the div */}
									<h3
										className="flex items-center text-base lg:text-lg text-left cursor-pointer text-black hover:shadow-lg transition-all duration-300 hover:scale-105 p-2 rounded-3xl"
										onClick={() => handleToggleCategory(category)}
									>
										<span className="w-4 h-4 lg:w-8 lg:h-8 bg-secondary rounded-full mr-2"></span>
										{category.charAt(0).toUpperCase() + category.slice(1)}
									</h3>
	
									{/* Show equipment under the category if expanded */}
									{expandedCategories[category] && (
										<div className="mt-2 ml-4 space-y-2 transition-all duration-300 ease-in-out shadow-inner p-3 bg-white rounded-3xl">
											{equipmentList[category].map((equipment, index) => (
												<div key={index} className="flex items-center space-x-4 hover:shadow-lg rounded-3xl transition-transform transform hover:scale-105 p-2">
													<span className="min-w-[12px] min-h-[12px] lg:min-w-8 lg:min-h-8 bg-secondary rounded-full"></span>
													<span className="text-black text-sm text-left lg:text-center">{formatEquipmentName(equipment)}</span>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
	
				{/* Facilities Column */}
				<div className="flex-1 shadow-md rounded-3xl px-4 py-4 transform lg:hover:scale-110 hover:shadow-xl transition duration-500 ease-in-out animate-fadeIn relative z-10 mt-0 lg:mt-8 mb-20">
					<h2 className="text-xl lg:text-2xl text-left text-primary mb-6">Facilities</h2>
					<div className="space-y-2"> {/* Ensure consistent spacing */}
						{displayedFacilities.map((facility, index) => (
							<div
								key={index}
								className="flex items-center text-base lg:text-lg p-2 space-x-4 transition-transform transform hover:scale-105 hover:shadow-md rounded-3xl mb-2"
							>
								<span className="w-4 h-4 lg:w-8 lg:h-8 bg-secondary rounded-full"></span>
								<span>{formatEquipmentName(facility)}</span>
							</div>
						))}
					</div>
	
					{/* Show More Button */}
					{facilities.length > 5 && (
						<div className="flex justify-center mt-4">
							<button
								onClick={handleShowAllFacilities}
								className="flex items-center bg-darkTertiary rounded-3xl text-logoGray hover:text-secondary hover:bg-logoGray transition duration-300 px-4"
							>
								{showAllFacilities ? 'Show Less' : 'Show More'}
							</button>
						</div>
					)}
				</div>
	
				{/* Classes Column */}
				<div className="flex-1 shadow-md rounded-3xl px-4 py-4 transform lg:hover:scale-110 hover:shadow-xl transition duration-500 ease-in-out animate-fadeIn relative z-10 mt-0 lg:mt-8 mb-20">
					<h2 className="text-xl lg:text-2xl text-left text-primary mb-6">Classes</h2>
					<div className="space-y-2"> {/* Ensure consistent spacing */}
						{displayedClasses.map((classItem, index) => (
							<div
								key={index}
								className="flex items-center text-base lg:text-lg p-2 space-x-4 transition-transform transform hover:scale-105 hover:shadow-md rounded-3xl"
							>
								<span className="w-4 h-4 lg:w-8 lg:h-8 bg-secondary rounded-full"></span>
								<span>{formatEquipmentName(classItem)}</span>
							</div>
						))}
					</div>
	
					{/* Show More Button */}
					{classes.length > 5 && (
						<div className="flex justify-center mt-4">
							<button
								onClick={handleShowAllClasses}
								className="flex items-center bg-darkTertiary rounded-3xl text-logoGray hover:text-secondary hover:bg-logoGray transition duration-300 px-4"
							>
								{showAllClasses ? 'Show Less' : 'Show More'}
							</button>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default GymDetailsComponent;
