import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, linkWithCredential, updateProfile } from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { doc, setDoc } from '@firebase/firestore';
import icon from '../../assets/CTA_01.png';
import Modal from "react-modal";

const SignUpPopup = ({ onClose, myData, switchModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [credential, setCredential] = useState();
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [name, setName] = useState('');
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState('');
  const [countryCode, setCountryCode] = useState('+91'); // Default country code

  useEffect(() => {
    const initializeRecaptcha = async () => {
        if (!window.recaptchaVerifier && document.getElementById('recaptcha-container')) {
            try {
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        console.log("reCAPTCHA solved");
                    },
                    'expired-callback': () => {
                        console.log("reCAPTCHA expired");
                    }
                }, auth);

                await window.recaptchaVerifier.render();
                // console.log('reCAPTCHA initialized');
            } catch (error) {
                // console.error("Error initializing reCAPTCHA", error);
            }
        }
    };

    initializeRecaptcha();

    // // Clean up reCAPTCHA when component unmounts
    return () => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
        }
    };
  }, []);

  

  const handlePhoneNumberSubmit = async () => {
    const fullPhoneNumber = `${countryCode}${phone}`;
    const appVerifier = window.recaptchaVerifier;
  
    if (!appVerifier) {
      alert("reCAPTCHA is not initialized. Please reload the page and try again.");
      return;
    }
  
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      alert("Verification code sent!");
    } catch (error) {
      // console.error("Error during phone number verification", error);
      alert("Error verifying phone number. Please try again.");
    }
  };  

  const handleCodeVerification = async () => {
    try {
      setCredential(PhoneAuthProvider.credential(verificationId, verificationCode));
      setShowEmailPasswordForm(true);
    } catch (error) {
      // console.error("Error verifying code", error);
    }
  };

  const addUserToDoc = async (user) => {
    try {
      const userRef = doc(db, 'user', user.uid);
      await setDoc(userRef, {
        name: name || '',
        age: age || 0,
        displayName: name || '',
        gym: myData.gymName || 'Not specified',
        email: email || '',
        gender: gender || ''
      });
      return true;
    } catch (error) {
      // console.log('Error : ', error);
      return error;
    }
  };

  const handleSignUp = async () => {
    try {
      if (email && password && confirmPassword && name && age) {
        if (password === confirmPassword) {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;

          const response = await addUserToDoc(user);

          if (response === true) {
            await updateProfile(user, { displayName: name });
            await linkWithCredential(user, credential);

            alert('Train Rex welcomes you!! Happy Fitness');
          }
        } else {
          alert('Passwords Do Not Match. Try Again!');
        }
      }
    } catch (error) {
      // console.error("Error signing up or linking phone number", error);
      alert("Error signing up or linking phone number : ", error);
    }
  };

  return (
    <>
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Sign In"
      className="fixed inset-0 flex items-center justify-center z-50 text-black"
      overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
    >
      <div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] lg:max-w-[80%] max-h-[900px] lg:max-h-[600px] flex flex-col lg:flex-row p-6 lg:p-14">
          {/* Left segment with the image occupying 2/3 of the modal */}
          <div className="w-full md:w-2/3 flex items-center justify-center p-0 lg:p-8 rounded-l-3xl">
              <img src={icon} alt="Logo" className="object-cover w-full h-full" />
          </div>

          {/* Right segment with form and buttons occupying 1/3 of the modal */}
          <div className="w-full flex flex-col items-center justify-center space-y-4 px-6">
            <div className="text-2xl lg:text-3xl text-primary font-semibold text-center">
              {showEmailPasswordForm ? 'Sign Up' : 'Verify Phone Number'}
            </div>
            {!showEmailPasswordForm ? (
              <>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter phone number"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <button
                  onClick={handlePhoneNumberSubmit}
                  className="bg-tertiary text-black px-6 py-3 rounded-full hover:bg-primary hover:text-tertiary transition w-full"
                >
                  Send Verification Code
                </button>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <button
                  onClick={handleCodeVerification}
                  className="bg-tertiary text-black px-6 py-3 rounded-full hover:bg-primary hover:text-tertiary transition w-full"
                >
                  Verify Code
                </button>
                <button
                  onClick={switchModal}
                  className="text-tertiary px-6 rounded-full transition w-full"
                >
                  Already have an account? <span className="underline hover:text-primary">Sign In</span>
                </button>
              </>
            ) : (
              <>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm password"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <input
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder="Enter your age"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <input
                  type="text"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  placeholder="Enter your gender"
                  className="px-4 py-2 border rounded-3xl w-full text-black"
                />
                <button
                  onClick={handleSignUp}
                  className="bg-tertiary text-black px-6 py-3 rounded-full hover:bg-primary hover:text-tertiary transition w-full"
                >
                  Sign Up
                </button>
              </>
            )}
        </div>

        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-tertiary hover:text-black transition text-4xl"
        >
          &times;
        </button>
      </div>
    </Modal>
    <div id="recaptcha-container" className="mt-4"></div>
    </>
  );
};

export default SignUpPopup;
