import { getSellingPrice } from "../gymDataHelpers/PriceHelpers";

/**
 * Gets the latitude and longitude of the gyms of the city by iterating and removing the first word from the address until coordinates are found.
 * @param {String} address - The full address to be geocoded.
 * @returns {Object|null} - Returns an object with lat and lng if found, otherwise null.
 */
const getCoordinatesOSM = async (address) => {
    
    const fetchCoordinates = async (formattedAddress) => {
        const url = `https://nominatim.openstreetmap.org/search?q=${formattedAddress}&format=json&limit=1`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.length > 0) {
                const { lat, lon } = data[0];
                return { lat, lng: lon };
            } else {
                return null;
            }
        } catch (error) {
            // console.error("Error fetching geocoding data: ", error);
            return null;
        }
    };

    // Iteratively remove the first word from the address and attempt to fetch coordinates
    let words = address.split(' ');
    while (words.length > 0) {
        const formattedAddress = encodeURIComponent(words.join(' '));
        const coordinates = await fetchCoordinates(formattedAddress);
        if (coordinates) {
            return coordinates; // Return if coordinates are found
        }
        words.shift(); // Remove the first word and retry
    }

    // console.error("No coordinates found for the address.");
    return null;
};



  
/**
 * Provides latitude and longitude of all the gyms of the city
 * @param {Object} gymData  -   all the gyms information
 * @returns {Object}        -   gyms latitude and longitude with their names
 */
export const getMapLocations = async (gymData) => {
    let mapLocations = [];
    let latAndLng = {}
    try {
		for (const gym of gymData) {
            // If latitude and longitude are already defined
            if (gym.latitude && gym.longitude) 
            {
                latAndLng = {
                    lat     :   gym.latitude,
                    lng     :   gym.longitude
                }
            }
            // else
			//     latAndLng = await getCoordinatesOSM(gym.address);
			if (latAndLng.lat && latAndLng.lng) {
                mapLocations.push({
                    ...latAndLng,
                    name            : gym.gymDisplayName,
                    locality        : gym.locality,
                    rating          : gym.rating,
                    address         : gym.address,
                    address2        : `${gym.cityDisplayName}, ${gym.pincode || ''}`,
                    gymName         : gym.gymName,
                    city            : gym.city,
                    image           : gym.image,
                    mrp             : gym.price,
                    exclusivePrice  : getSellingPrice(gym.exclusivePrice),
                });
			}
		}
    } catch (error) {
      	// console.log("Error fetching map locations: ", error);
    }
    return mapLocations;
};
  