import emailjs from 'emailjs-com';
import { useState } from 'react';

function EventContact() {
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', occupation: '' });
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendEmail = (params) => {
        const serviceId = 'service_nwr8lcg';
        const templateId = 'template_2w9py86';
        const userId = 'R_5m_rCvYgVXMFU5S';

        emailjs.send(serviceId, templateId, params, userId)
            .then(() => {
                setAlertMessage("Thank you for connecting with us!");
                setShowAlert(true);
                setFormData({ name: '', email: '', phone: '', occupation: '' });
            })
            .catch(() => {
                setAlertMessage("Failed to send the message. Please try again.");
                setShowAlert(true);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, phone, occupation } = formData;

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return showError("Enter a valid email.");
        if (!/^\d{10}$/.test(phone)) return showError("Enter a valid 10-digit phone number.");
        if (!name || !email || !phone || !occupation) return showError("All fields are required.");

        sendEmail({ name, email, phone, occupation });
    };

    const showError = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <div className="h-screen bg-gradient-to-r from-blue-100 to-indigo-100 flex items-center justify-center">
            <div className="bg-tertiary shadow-lg rounded-lg p-8 w-full max-w-lg">
                <h2 className="text-3xl font-semibold text-center mb-6 text-primary">Connect with Us!</h2>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <input
                            name="name"
                            placeholder="👤 What's your name?"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full h-12 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                        />
                        <input
                            name="email"
                            placeholder="✉️ Your Email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full h-12 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                        />
                        <input
                            name="phone"
                            placeholder="📞 Phone Number"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full h-12 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                        />
                        <input
                            name="occupation"
                            placeholder="💼 Your Occupation"
                            value={formData.occupation}
                            onChange={handleChange}
                            className="w-full h-12 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-300"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full mt-6 py-3 rounded-lg bg-secondary text-white font-semibold hover:bg-primary transition-all duration-300"
                    >
                        Submit
                    </button>
                </form>
                {showAlert && (
                    <CustomAlert message={alertMessage} onClose={handleCloseAlert} />
                )}
            </div>
        </div>
    );
}

const CustomAlert = ({ message, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <p className="mb-4">{message}</p>
            <button
                onClick={onClose}
                className="bg-secondary text-white px-4 py-2 rounded-lg hover:bg-primary transition-all duration-300"
            >
                Close
            </button>
        </div>
    </div>
);

export default EventContact;
