import { addDoc, collection, doc, getDoc, getDocs, updateDoc, where, query } from "@firebase/firestore";
import { db } from "../config/firebase";
import { addMonthsToDate } from "./userDatabaseHelper";
import { generateRandomStringId, getCurrentYear, getDate } from "./basicHelper";
import { cityList, financeList, gymList, revenueList, userList } from "../components/Databases";

/**
 * Stores data in gym's database
 * @param {Object} myData - User data including city, gymName, months, userID, contactNo
 * @param {Object} response - Response object from Razorpay containing paymentID, orderID, signature
 * @returns {boolean} - True if save successful, false otherwise
 */
export const storeGymData = async (myData, response) => {
    let startDate   = getDate();
    let endDate     = addMonthsToDate(startDate, myData.months);

    try {
        const gymRef = collection(db, cityList, myData.city, gymList);
        const gymDocRef = doc(gymRef, myData.gymName);
        let gymDoc = await getDoc(gymDocRef);

        // If gym doesn't exist
        if (!gymDoc.exists()) {
            // const city = myData.city.toLowerCase().replace(/\s+/g, '');
            const gymName = myData.gymName.toLowerCase().replace(/\s+/g, '');
            const gymQuery = query(gymRef, where('codeName', '==', gymName));
            const querySnapshot = await getDocs(gymQuery);

            if (!querySnapshot.empty) {
                // If the gym document exists, get the first matching document
                gymDoc = querySnapshot.docs[0];
            } else {
                return false;
            }
        }
        // console.log('Flag for checking if user exists : ', myData.user.uid);
        const userRef = collection(db, cityList, myData.city, gymList, gymDoc.id, userList);
        const userQuery = query(userRef, where('userID', '==', myData.user.uid));
        const userSnapshot = await getDocs(userQuery);

        // If user is renewing
        if (!userSnapshot.empty) {
            
            const userDoc = userSnapshot.docs[0];
            const userData = userDoc.data();

            endDate = userData.membershipTill ? addMonthsToDate(userData.membershipTill, myData.months) : endDate;

            await updateDoc(userDoc.ref, {
                status          : true,
                membershipTill  : endDate,
                // Add a gym membership ID if necessary
            });
        }
        // If user is new to the gym
        else {
            // console.log('User Doc Doesnt exist!');
            const userDBRef = doc(collection(db, 'user'), myData.user.uid);
            const userDBDoc = await getDoc(userDBRef);
            const userDBData = userDBDoc.data();
            // console.log('userDBData : ', userDBData);
            await addDoc(userRef, {
                gender              : userDBData.gender || myData.age || '',
                age                 : userDBData.age || myData.age,
                phoneNumber         : myData.user.phoneNumber || myData.phoneNumber || '',
                profilePic          : userDBData.profilePic || "",
                email               : myData.user.email,
                userID              : myData.user.uid,
                userName            : userDBData.name,
                membershipFrom      : startDate,
                memberSince         : getDate(),
                membershipTill      : endDate,
                status              : true,
                DOB                 : myData.dateOfBirth || "",
                // Add a gym membership ID if necessary
            });
        }

        // Add the receipts to another collection
        const revenueRef = collection(
            db,
            cityList,
            myData.city,
            gymList,
            gymDoc.id,
            financeList,
            getCurrentYear(),
            revenueList
        );
        storeReceiptinDatabase(revenueRef, {
            ...myData,
            startDate   : startDate,
            endDate     : endDate,
        }, response.razorpay_payment_id);

        return true;

    } catch (error) {
        return false;
    }
};

/**
 * Function used to store receits
 * @param {Ref} databaseRef   - The Reference of the database
 * @param {Object} myData     - The Data to be inserted in the database
 * @param {String} paymentID  - The paymentID of the receipt
 */
const storeReceiptinDatabase = async (databaseRef, myData, paymentID) => {
    try {
      await addDoc ( databaseRef, {
        timestamp       : new Date().toISOString(),
        paymentID       : paymentID,
        orderType       : myData.orderType || "Gym Membership",
        months          : myData.months || null,
        classSchedule   : myData.classSchedule || null,
        userID          : myData.user.uid || myData.userID || "",
        amount          : myData.amount || null,
        userName        : myData.user.displayName || myData.userName,
        email           : myData.user.email || myData.email || "",
        personalTrainer : myData.hasTrainer ? myData.trainerName : false, // If hasTrainer is True, store their name, else false
        phoneNumber     : myData.user.phoneNumber || myData.phoneNumber || '',
        startDate       : myData.startDate || null,
        endDate         : myData.endDate || null,
        gymContactNo    : myData.contactNo || '',
        receiptNumber   : generateRandomStringId(),
      })
    } catch (error) {
    //   console.error('Error in storing Receipt : ', error);
    }
};

export const updateMemberShipBoughtCount = async (city, gymName) => {
    const gymRef = collection(db, cityList, city, gymList);
    const gymDocRef = doc(gymRef, gymName);
    const gymDoc = await getDoc(gymDocRef);
    const gymData = gymDoc.data();

    // Initialize membershipSold if it doesn't exist
    let membershipSold = {
        thisWeek: 0,
        total: 0,
        lastUpdated: null, // track last update for thisWeek
    };

    // If the membershipSold field exists, use the current data
    if (gymData && gymData.membershipSold) {
        membershipSold = gymData.membershipSold;
    }

    const today = new Date();
    const currentDay = today.getDay(); // get the current day (0 = Sunday, 1 = Monday, etc.)
    const lastUpdatedDate = membershipSold.lastUpdated ? new Date(membershipSold.lastUpdated) : null;

    // Check if today is Monday and if it's a new week compared to last update
    if (currentDay === 1 && (!lastUpdatedDate || lastUpdatedDate.getDay() !== 1)) {
        // Reset thisWeek on Monday
        membershipSold.thisWeek = 0;
    }

    // Update membership counts
    membershipSold.thisWeek += 1;
    membershipSold.total += 1;
    membershipSold.lastUpdated = today.toISOString(); // Update the lastUpdated timestamp

    await updateDoc(gymDocRef, {
        membershipSold: membershipSold,
    });
};
