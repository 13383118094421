import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FitnessOptions = [
    { title: "All", type: "" },
    { title: "Gyms", type: "gyms" },
    { title: "Yoga", type: "yoga" },
    { title: "Zumba", type: "zumba" },
];

const BudgetOptions = [
    { title: "Less than Rs. 1500", type: "1" },
    { title: "Rs. 1500 - 3000", type: "3" },
    { title: "Rs. 3000 - 4500", type: "2" },
    { title: "More than Rs. 4500", type: "4" },
];

const FacilitiesOptions = [
    { title: "Boxing Ring", value: "BoxingRing" },
    { title: "Cardio Area", value: "CardioArea" },
    { title: "Lounge And Cafe", value: "LoungeAndCafe" },
    { title: "MMA", value: "MMA" },
    { title: "Sauna", value: "Sauna" },
    { title: "Spa", value: "Spa" },
    { title: "Strength Area", value: "StrengthArea" },
    { title: "Valet Parking", value: "ValetParking" },
];

const Sidebar = () => {
    const [searchParams] = useSearchParams();
    const [selectedBudget, setSelectedBudget] = useState('');
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [fitnessOpen, setFitnessOpen] = useState(false);
    const [budgetOpen, setBudgetOpen] = useState(true);
    const [facilitiesOpen, setFacilitiesOpen] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const navigate = useNavigate();

    // Check if the screen size is small
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1022);
        };

        handleResize(); // Set the initial value
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Close the budget and facilities dropdowns on small screens
    useEffect(() => {
        if (isSmallScreen) {
            setBudgetOpen(false);
            setFacilitiesOpen(false);
        } else {
            setBudgetOpen(true);
            setFacilitiesOpen(true);
        }
    }, [isSmallScreen]);

    useEffect(() => {
        const facilitiesFromUrl = searchParams.get('facilities');
        const budgetFromUrl = searchParams.get('budget');

        if (facilitiesFromUrl) {
            setSelectedFacilities(facilitiesFromUrl.split(','));
        }
        if (budgetFromUrl) {
            setSelectedBudget(budgetFromUrl);
        }
    }, [searchParams]);

    const handleBudgetChange = (type) => {
        setSelectedBudget(type);
        navigate(`/Delhi%20NCR/result?search=gy&budget=${type}&facilities=${selectedFacilities.join(',')}`);
    };

    const handleFacilitiesChange = (facility) => {
        const updatedFacilities = selectedFacilities.includes(facility)
            ? selectedFacilities.filter((f) => f !== facility)
            : [...selectedFacilities, facility];

        setSelectedFacilities(updatedFacilities);
        navigate(`/Delhi%20NCR/result?search=gy&budget=${selectedBudget}&facilities=${updatedFacilities.join(',')}`);
    };

    return (
        <div className='lg:w-64 py-5 rounded-xl flex flex-col gap-5'>
            {/* <div className='border-[1px] rounded-lg bg-white'>
                <div
                    className='bg-primary rounded-t-lg py-2 text-white px-4 cursor-pointer flex justify-between items-center'
                    onClick={() => setFitnessOpen(!fitnessOpen)}
                >
                    <span>Fitness Options</span>
                    <span>{fitnessOpen ? '▲' : '▼'}</span>
                </div>
                {fitnessOpen && (
                    <div className='flex flex-col gap-2 py-2 px-4'>
                        {FitnessOptions.map((option, index) => (
                            <div key={index} className='cursor-pointer'>
                                {option.title}
                            </div>
                        ))}
                    </div>
                )}
            </div> */}
            {/* Budget Options */}
            <div className='rounded-3xl'>
                <div
                    className={`bg-tertiary  ${budgetOpen ? 'rounded-t-3xl' : 'rounded-full'} border-[1px] border-logoGray py-2 text-logoGray px-4 cursor-pointer flex justify-between items-center`}
                    onClick={() => setBudgetOpen(!budgetOpen)}
                >
                    <span>Budget Options</span>
                    <span>{budgetOpen ? '▲' : '▼'}</span>
                </div>
                {budgetOpen && (
                    <div className='flex flex-col gap-2 py-2 px-4 border-[1px] border-logoGray rounded-b-3xl bg-tertiary'>
                        {BudgetOptions.map((option, index) => (
                            <div key={index} onClick={() => handleBudgetChange(option.type)} className='flex items-center hover:bg-secondary py-1 text-sm rounded-lg cursor-pointer px-2 gap-2'>
                                <input
                                    type='radio'
                                    name='budget'
                                    className='mr-2'
                                    checked={selectedBudget === option.type}
                                />
                                <div>{option.title} </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Facilities Options */}
            <div className='rounded-3xl'>
                <div
                    className={`bg-tertiary  ${facilitiesOpen ? 'rounded-t-3xl' : 'rounded-full'} border-[1px] border-logoGray py-2 text-logoGray px-4 cursor-pointer flex justify-between items-center`}
                    onClick={() => setFacilitiesOpen(!facilitiesOpen)}
                >
                    <span>Facilities</span>
                    <span>{facilitiesOpen ? '▲' : '▼'}</span>
                </div>
                {facilitiesOpen && (
                    <div className='flex flex-col gap-2 py-2 px-4 border-[1px] border-logoGray rounded-b-3xl bg-tertiary'>
                        {FacilitiesOptions.map((option, index) => (
                            <div key={index} onClick={() => handleFacilitiesChange(option.value)} className='flex items-center hover:bg-secondary py-1 text-sm rounded-lg cursor-pointer px-2 gap-2'>
                                <input
                                    type='checkbox'
                                    className='mr-2'
                                    checked={selectedFacilities.includes(option.value)}
                                    // onChange={}
                                />
                                <div> {option.title} </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
